import type { ReactElement } from 'react';
import React from 'react';
import { ToggleButtonGroup as MuiToggleButtonGroup } from '@material-ui/lab';
import ToggleButton from '@material-ui/lab/ToggleButton';

export interface ToggleButtonProps<T> {
  value: T;
  label: React.ReactNode;
  key: string;
}

export interface ToggleButtonGroupProps<T> {
  id?: string;
  value: T;
  exclusive?: boolean;
  onChange: (event: React.MouseEvent<HTMLElement>, newValue: T) => void;
  options: ToggleButtonProps<T>[];
  disabled?: boolean;
  className?: string;
}

const ToggleButtonGroup = <T,>({
  id,
  value,
  onChange,
  options,
  exclusive = true,
  disabled = false,
  className,
}: ToggleButtonGroupProps<T>): ReactElement => {
  return (
    <MuiToggleButtonGroup
      id={id}
      value={value}
      exclusive={exclusive}
      onChange={onChange}
      size="small"
      className={className}
    >
      {options.map(({ key, value: currValue, label }) => (
        <ToggleButton
          key={key}
          value={currValue}
          disabled={disabled}
          classes={{
            root: 'border-2 border-slate-500 px-12 py-2 normal-case text-slate-800 rounded-md',
            selected: '[&&]:border-2 [&&]:text-teal-700 [&&]:bg-teal-100 [&&]:border-teal-700',
          }}
        >
          {label}
        </ToggleButton>
      ))}
    </MuiToggleButtonGroup>
  );
};

export default ToggleButtonGroup;
