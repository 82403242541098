import React from 'react';

import EmptyStateIcon from '~/components/AiChat/SideBarDialog/icons/EmptyStateIcon';
import { InsightPanel } from '~/components/AiChat/SideBarDialog/Insights/Insight';
import type { AiChatReference, Insight } from '~/components/AiChat/types';
import EmptyState from '~/components/core/EmptyState';

interface InsightsProps {
  insights: Insight[];
  references: Record<string, AiChatReference>;
}

export const Insights: React.FC<InsightsProps> = ({ insights, references }) => {
  return (
    <div className="__INIGHTS_ROOT__ mt-16 flex h-[calc(100vh-230px)] flex-col gap-20 overflow-scroll">
      <div className="__INIGHTS_HEADER__ h-min-[100px] gap-100 flex flex-col">
        <h2 className="mx-20" hidden={!insights?.length}>
          Claim Insights
        </h2>
        {!insights?.length && (
          <div className="flex h-[calc(100vh-650px)] w-full flex-col items-center justify-center px-[100px]">
            <EmptyState
              className="text-center"
              title="No recommended insights yet"
              illustration={<EmptyStateIcon height={200} width={200} />}
            />
          </div>
        )}
        {insights?.length > 0 && (
          <ul className="my-0 ml-20 flex flex-col gap-20 p-0">
            {insights?.map((insight: Insight) => (
              <InsightPanel insight={insight} references={references} key={insight.insight_key} />
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
