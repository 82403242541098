import React from 'react';

import CardDialog from '~/components/CardDialog';
import AlertBanner from '~/components/core/AlertBanner';
import Button from '~/components/core/Atomic/Buttons/Button';
import type { Policy } from '~/components/Fnol/FnolAiInbox/types';
import { PolicySearchResults } from '~/components/PolicySearch';

interface PolicyCardProps {
  policy: Policy;
  onRemove: () => void;
  onChangePolicy: () => void;
  onCancel: () => void;
  viewOnly: boolean;
}

export const PolicyCard: React.FC<PolicyCardProps> = ({
  policy,
  onRemove,
  onChangePolicy,
  onCancel,
  viewOnly = false,
}: PolicyCardProps) => {
  const [isRemoved, setIsRemoved] = React.useState(false);
  const resultsDict = {
    [policy.policy_type]: [policy],
  };

  const removePolicyHandler = () => {
    setIsRemoved(true);
  };

  return (
    <CardDialog isDialog title="Policy Found" onClose={onCancel} maxWidth="lg" fullWidth>
      <div className="flex flex-col gap-12">
        {isRemoved ? (
          <AlertBanner
            alertType={AlertBanner.ALERT_TYPES.WARNING}
            note="Please note that by removing the policy found all relevant information will be removed, also from the FNOL draft created."
            withIcon
          />
        ) : (
          <PolicySearchResults
            isDialog={false}
            noCardTitle
            classes={{}}
            searchPoliciesResults={isRemoved ? {} : resultsDict}
            onSelectPolicy={removePolicyHandler}
            policyTypes={[policy.policy_type]}
            selectButtonText="REMOVE"
            disableSelectButton={viewOnly}
          />
        )}

        <div className="mt-12 flex flex-row-reverse items-center gap-12">
          <Button
            onClick={() => onRemove()}
            className=" bg-blue-700 text-white"
            variant="contained"
            disabled={viewOnly || !isRemoved}
          >
            Save
          </Button>
          <Button onClick={onCancel} className="ml-auto " variant="outlined">
            Cancel
          </Button>
          <Button onClick={onChangePolicy} className="mr-auto text-blue-700" variant="text" disabled={viewOnly}>
            Change Policy
          </Button>
        </div>
      </div>
    </CardDialog>
  );
};
