import React from 'react';

import SvgIcon from '~/components/core/SvgIcon';

const ComplexityIcon = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      strokeWidth={0.3}
      d="M19.2797 7.69504C18.3248 6.73817 17.1899 5.97979 15.9405 5.46361C14.6911 4.94743 13.3518 4.68367 12 4.68754H11.9634C6.29719 4.70723 1.6875 9.38067 1.6875 15.106V17.25C1.6875 17.5981 1.82578 17.932 2.07192 18.1781C2.31806 18.4243 2.6519 18.5625 3 18.5625H21C21.3481 18.5625 21.6819 18.4243 21.9281 18.1781C22.1742 17.932 22.3125 17.5981 22.3125 17.25V15C22.3162 13.6422 22.05 12.2972 21.5293 11.0431C21.0087 9.78906 20.244 8.65099 19.2797 7.69504ZM21.1875 17.25C21.1875 17.2998 21.1677 17.3475 21.1326 17.3826C21.0974 17.4178 21.0497 17.4375 21 17.4375H10.8544L16.2047 10.081C16.2887 9.96022 16.322 9.81137 16.2976 9.66632C16.2731 9.52128 16.1928 9.39157 16.0739 9.30502C15.955 9.21846 15.8069 9.18192 15.6613 9.20325C15.5158 9.22458 15.3844 9.30206 15.2953 9.4191L9.46313 17.4375H3C2.95027 17.4375 2.90258 17.4178 2.86742 17.3826C2.83225 17.3475 2.8125 17.2998 2.8125 17.25V15.106C2.81291 14.7574 2.83231 14.409 2.87063 14.0625H5.25C5.39918 14.0625 5.54226 14.0033 5.64775 13.8978C5.75324 13.7923 5.8125 13.6492 5.8125 13.5C5.8125 13.3509 5.75324 13.2078 5.64775 13.1023C5.54226 12.9968 5.39918 12.9375 5.25 12.9375H3.06656C3.99375 9.03754 7.36219 6.08067 11.4375 5.83035V8.25004C11.4375 8.39923 11.4968 8.5423 11.6023 8.64779C11.7077 8.75328 11.8508 8.81254 12 8.81254C12.1492 8.81254 12.2923 8.75328 12.3977 8.64779C12.5032 8.5423 12.5625 8.39923 12.5625 8.25004V5.83035C14.5408 5.95178 16.4268 6.70938 17.9394 7.99017C19.452 9.27096 20.5101 11.0063 20.9559 12.9375H18.75C18.6008 12.9375 18.4577 12.9968 18.3523 13.1023C18.2468 13.2078 18.1875 13.3509 18.1875 13.5C18.1875 13.6492 18.2468 13.7923 18.3523 13.8978C18.4577 14.0033 18.6008 14.0625 18.75 14.0625H21.1406C21.1716 14.3719 21.1875 14.685 21.1875 15V17.25Z"
    />
  </SvgIcon>
);

export default ComplexityIcon;
