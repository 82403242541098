import { useEffect, useRef } from 'react';

import mixpanel from '~/components/CmsMain/mixpanel';
import { isProductionEnv } from '~/Utils';

interface MixpanelComponentLifetimeEvent {
  duration_ms: number;
  duration_seconds: number;
  [key: string]: unknown; // Allow for additional custom properties
}

interface useMixpanelComponentLifetimeProps {
  eventName: string;
  additionalProperties?: Record<string, unknown>;
  enabled?: boolean;
}

/**
 * A custom hook that tracks component lifetime and sends the duration to Mixpanel
 * on component unmount.
 *
 * @param {useMixpanelComponentLifetimeProps} props - Hook configuration
 * @returns {void}
 *
 * @example
 * function MyComponent() {
 *   useMixpanelComponentLifetime({
 *     eventName: MIXPANEL_EVENTS.MYCOMPONENT_LIFETIME
 *     additionalProperties: { page: 'homepage' }
 *   });
 *   return <div>Content</div>;
 * }
 */
const useMixpanelComponentLifetime = ({
  eventName,
  additionalProperties = {},
  enabled = true,
}: useMixpanelComponentLifetimeProps): void => {
  // Use ref to store mount time to avoid recreating effect on every render
  const mountTimeRef = useRef<number | null>(null);

  useEffect(() => {
    // Skip if tracking is disabled
    if (!enabled || !eventName) return;

    // Store mount time
    mountTimeRef.current = Date.now();

    // Cleanup function that runs on unmount
    return () => {
      try {
        const unmountTime = Date.now();
        const duration = mountTimeRef.current ? unmountTime - mountTimeRef.current : 0;

        const eventData: MixpanelComponentLifetimeEvent = {
          duration_ms: duration,
          duration_seconds: Math.round(duration / 1000),
          ...additionalProperties,
        };

        mixpanel.track(eventName, eventData);
      } catch (error) {
        if (!isProductionEnv()) {
          const errorMessage = error instanceof Error ? error.message : 'Unknown error';
          // eslint-disable-next-line no-console
          console.error('Error tracking component lifetime:', errorMessage);
        }
      }
    };
    // We only want this to run on component load and unload
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useMixpanelComponentLifetime;
