import type { ReactNode } from 'react';
import React from 'react';
import axios from 'axios';

import { MessageReferences } from '~/components/AiChat/components/Message/MessageReferences';
import { RoundedPanel } from '~/components/AiChat/SideBarDialog/components/RoundedPanel/RoundedPanel';
import { INSIGHTS_ICONS, replaceDatesWithLocaleString } from '~/components/AiChat/SideBarDialog/utils';
import type { AiChatReference, Insight } from '~/components/AiChat/types';
import { useClaim } from '~/components/ClaimContainer';
import { MainCard } from '~/components/core';
import Chip from '~/components/core/Atomic/Chip/Chip';
import { MarkdownSection } from '~/components/core/MarkdownSection';
import { reportAxiosError } from '~/Utils';
import cn from '~/Utils/cn';

interface InsightPanelProps {
  insight: Insight;
  references: Record<string, AiChatReference>;
}

export const InsightPanel: React.FC<InsightPanelProps> = ({ insight, references }) => {
  const { claim } = useClaim();
  const { insight_text, insight_type, insight_key, sub_title, related_documents } = insight;
  const referencesIds = related_documents ? new Set(related_documents.map((doc) => doc?.document_id || '')) : [];
  const [isOpen, setIsOpen] = React.useState(false);

  const extraComponent =
    insight.insight_key === 'complexity' && insight.insight_metadata?.complexity ? (
      <ComplexityBadge complexity={insight.insight_metadata.complexity as string} />
    ) : null;

  return (
    <div
      className={cn('animate-fadeIn gap-10 relative flex w-full items-center bg-white', { ['bg-slate-100']: isOpen })}
    >
      <MainCard
        key="contained"
        collapsible
        title={<InsightTitle title={insight.title} extraComponent={extraComponent} />}
        type="elevated-contained"
        isOpen={isOpen}
        direction="right"
        onCollapseClick={async (cardState) => {
          setIsOpen(cardState);
          if (!insight.is_dismissed) {
            try {
              await axios.post(`/api/v1/claims/${claim.id}/chat/insights/${insight.id}/dismiss`);
            } catch (error) {
              await reportAxiosError(error, false);
            }
          }
        }}
        titleAction={
          <div className="ml-auto box-content flex h-32 w-32 shrink-0 items-center justify-center rounded-md p-4">
            {INSIGHTS_ICONS[insight_key] || INSIGHTS_ICONS[insight_type]}
          </div>
        }
      >
        <div className="relative flex w-full flex-col gap-12">
          <span className="text-md rounded-lg bg-white px-16 py-8" hidden={!sub_title}>
            {sub_title}
          </span>
          <div className="flex flex-col gap-12 ">
            <RoundedPanel variant="clear">
              <MarkdownSection markdown={replaceDatesWithLocaleString(insight_text)} />
            </RoundedPanel>
          </div>
        </div>

        <MessageReferences referencesIds={[...referencesIds]} references={references} />
      </MainCard>
    </div>
  );
};

const InsightTitle: React.FC<{ title: string; extraComponent?: ReactNode }> = ({ title, extraComponent }) => {
  if (extraComponent) {
    return (
      <div className="inline-flex w-full items-center justify-between">
        {title}
        {extraComponent}
      </div>
    );
  }

  return <>{title}</>;
};

const ComplexityBadge: React.FC<{ complexity: string }> = ({ complexity }) => {
  const complexityKey = complexity.toLowerCase();

  return (
    <Chip
      className={cn('border-1 border-solid border-blue-500 bg-blue-100 text-blue-500', {
        'border-green-700 bg-green-100 text-green-700': complexityKey === 'low',
        'border-yellow-800 bg-yellow-100 text-yellow-800': complexityKey === 'medium',
        'border-red-700 bg-red-100 text-red-700': complexityKey === 'high',
      })}
      label={complexity}
    />
  );
};
