import React from 'react';
import { Paper } from '@material-ui/core';

import UserClaimsNotifications from '~/components/UserClaimsNotificationsContainer';

const DismissedNotificationsPage: React.FC = () => {
  return (
    // The <Paper> is needed for the scroll to work
    <Paper>
      <UserClaimsNotifications showFiltering defaultSortByDate onlyDismissed />
    </Paper>
  );
};

export default DismissedNotificationsPage;
