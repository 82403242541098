import type { MouseEventHandler } from 'react';
import React from 'react';
import { Link } from '@material-ui/core';

interface NotificationNavigationLinkProps {
  onClick: MouseEventHandler<HTMLSpanElement>;
  text: string;
}

const NotificationNavigationLink: React.FC<NotificationNavigationLinkProps> = ({ onClick, text }) => {
  return (
    <div className="mb-16">
      <Link onClick={onClick} className="cursor-pointer font-bold text-teal-700 no-underline hover:underline">
        {text}
      </Link>
    </div>
  );
};

export default NotificationNavigationLink;
