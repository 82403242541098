import React from 'react';

import { CliveChip } from '~/components/AiChat/CliveChip/CliveChip';
import type { ToggleButtonProps } from '~/components/core/Molecules/Fields/ToggleButtonGroup';
import ToggleButtonGroup from '~/components/core/Molecules/Fields/ToggleButtonGroup';

interface CliveTranslationSwitchProps {
  onChange: (value: string) => void;
  selected: string;
  options: ToggleButtonProps<string>[];
}

export const CliveTranslationSwitch: React.FC<CliveTranslationSwitchProps> = ({ onChange, options, selected }) => {
  return (
    <CliveChip
      content={
        <div className="flex items-center gap-24 text-base font-normal">
          This Email has been translated
          <ToggleButtonGroup
            exclusive
            options={options}
            value={selected}
            onChange={(event, value) => value !== null && onChange(value)}
          />
        </div>
      }
    />
  );
};
