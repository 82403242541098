import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import ToggleButtonGroup from '~/components/core/Molecules/Fields/ToggleButtonGroup';
import { useCms } from '~/components/hooks/useCms';
import NotificationNavigationLink from '~/components/Notifications/NotificationNavigationLink';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';

import { isFeatureEnabled, isQoverClaim } from '../Utils';

import ClaimNotificationsCard from './ClaimNotificationsCard/ClaimNotificationsCard';
import { useClaim } from './ClaimContainer';

const SEEN_NOTIFICATIONS_OPTIONS = [
  {
    key: 'all',
    value: false,
    label: 'Show All',
  },
  {
    key: 'mine',
    value: true,
    label: 'Show Mine',
  },
];
function ToggleSeenNotifications(props) {
  const { showOnlyCurrentUserNotifications, setShowOnlyCurrentUserNotifications } = props;

  return (
    <ToggleButtonGroup
      id="ToggleSeenNotifications"
      value={showOnlyCurrentUserNotifications}
      exclusive
      onChange={(event, newValue) => newValue !== null && setShowOnlyCurrentUserNotifications(newValue)}
      options={SEEN_NOTIFICATIONS_OPTIONS}
    />
  );
}

ToggleSeenNotifications.propTypes = {
  showOnlyCurrentUserNotifications: PropTypes.bool.isRequired,
  setShowOnlyCurrentUserNotifications: PropTypes.func.isRequired,
};

function ClaimNotificationsContainer({ maxHeight }) {
  const { claim, onClaimUpdate } = useClaim();
  const { userOrganization } = useCms();
  const [isDismissedNotificationsShown, setIsDismissedNotificationsShown] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [showOnlyCurrentUserNotifications, setShowOnlyCurrentUserNotifications] = React.useState(
    isQoverClaim(claim) ? false : true
  );

  const isNotifications2Enabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.NOTIFICATIONS_2);

  const getUpdatedClaim = React.useCallback(async () => {
    const { data: updatedClaim } = await onClaimUpdate();
    setIsFirstLoad(false);
    return updatedClaim;
  }, [onClaimUpdate]);

  const getClaimNotifications = React.useCallback(
    async (
      shouldReturnDismissed,
      shouldReturnFutureReminders,
      pageNumber,
      resultsPerPage,
      sortByColumn,
      typeSubtypeFilters,
      withReadStatus = false,
      subOrganizationIds,
      categoriesIds,
      statuses,
      priorities,
      startDueDate,
      endDueDate,
      adjustersIds
    ) => {
      let updatedClaim = claim;
      if (!isFirstLoad) {
        updatedClaim = await getUpdatedClaim();
      }
      let params = {
        include_dismissed: shouldReturnDismissed ? 'only_dismissed' : 'not_dismissed',
        show_future_reminders: shouldReturnFutureReminders ? true : undefined,
        page_number: pageNumber ? pageNumber : undefined,
        results_per_page: resultsPerPage ? resultsPerPage : undefined,
        sort_by_column: sortByColumn ? sortByColumn : undefined,
        type_subtype_filters: typeSubtypeFilters ? typeSubtypeFilters : undefined,
        sub_organization_ids: subOrganizationIds,
        categories_ids: categoriesIds,
        statuses,
        priorities,
        start_due_date: startDueDate,
        end_due_date: endDueDate,
        adjusters_ids: adjustersIds,
        with_read_status: withReadStatus ? true : undefined,
      };
      let url = `/api/v1/claims/${claim.id}/notifications`;
      if (showOnlyCurrentUserNotifications || isNotifications2Enabled) {
        url = '/api/v1/claims/claims_notifications';
        params = { ...params, claim_id: claim.id };
      }

      return axios.get(url, { params }).then((res) => ({ ...res.data, exposures: updatedClaim.exposures }));
    },
    [claim, getUpdatedClaim, isFirstLoad, isNotifications2Enabled, showOnlyCurrentUserNotifications]
  );

  return (
    <ClaimNotificationsCard
      showSubOrg={false}
      getClaimNotifications={getClaimNotifications}
      AdditionalHeaderComponent={
        <ToggleSeenNotifications
          showOnlyCurrentUserNotifications={showOnlyCurrentUserNotifications}
          setShowOnlyCurrentUserNotifications={setShowOnlyCurrentUserNotifications}
        />
      }
      maxHeight={maxHeight}
      showRecipientUser
      useDefaultAssigneeFromPreferences
      onlyDismissed={isDismissedNotificationsShown}
      dismissedNavigationComponent={
        isDismissedNotificationsShown ? (
          <NotificationNavigationLink
            text="Back to active notifications"
            onClick={() => {
              setIsDismissedNotificationsShown(false);
            }}
          />
        ) : (
          <NotificationNavigationLink
            onClick={(e) => {
              e.preventDefault();
              setIsDismissedNotificationsShown(true);
            }}
            text="See dismissed notifications"
          />
        )
      }
    />
  );
}

ClaimNotificationsContainer.propTypes = {
  maxHeight: PropTypes.string,
};

export default ClaimNotificationsContainer;
