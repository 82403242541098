import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

import { usePollingWithFailureTracking } from '~/components/AiChat/hooks/usePollingWithFailureTracking';
import {
  generateAdjusterAssignmentActionData,
  generateCommunicationActionData,
  generateOpenExposureActionData,
  generateSetReserveActionData,
} from '~/components/AiChat/SideBarDialog/Actions/demoActions';
import { useActionsFeatureFlags } from '~/components/AiChat/SideBarDialog/Actions/useActionsFeatureFlags';
import type { AiAction, Exposure, ReserveActionData } from '~/components/AiChat/types';
import { AI_ACTION_TYPES } from '~/components/AiChat/types';
import { useClaim } from '~/components/ClaimContainer';

const POLLING_DELAY = 5000;

export interface IActionsContextData {
  actions: AiAction[];
  isLoading: boolean;
  isError: boolean;
  reloadData: () => Promise<void>;
}

const ActionsContext = React.createContext<IActionsContextData>({
  actions: [],
  isLoading: true,
  isError: false,
  reloadData: Promise.resolve,
});

export const ActionsContextProvider: React.FC = ({ children }) => {
  const [actions, setActions] = useState<AiAction[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [demoActions, setDemoActions] = useState<AiAction[]>([]);
  const { claim } = useClaim();
  const { isActionsTabEnabled, actionFlagEnabledMapping, isActionsDemoEnabled } = useActionsFeatureFlags();
  const [shouldBuildDemoActions, setShouldBuildDemoActions] = useState(isActionsDemoEnabled);

  const generateStaticDemoActions = useCallback(
    (handlingAdjuster: string, reserves: ReserveActionData[], claimCreationDate: Date): AiAction[] => {
      const allowedDemoActions = [] as AiAction[];
      if (!actionFlagEnabledMapping) return allowedDemoActions;

      if (actionFlagEnabledMapping[AI_ACTION_TYPES.ADJUSTER_ASSIGNMENT] && handlingAdjuster) {
        allowedDemoActions.push(generateAdjusterAssignmentActionData(claimCreationDate, handlingAdjuster));
      }

      if (actionFlagEnabledMapping[AI_ACTION_TYPES.OPEN_EXPOSURE]) {
        claim.exposures?.forEach((exposure: Exposure) => {
          allowedDemoActions.push(generateOpenExposureActionData(claimCreationDate, exposure));
        });
      }

      if (actionFlagEnabledMapping[AI_ACTION_TYPES.SET_RESERVE]) {
        reserves?.forEach((reserve: ReserveActionData) => {
          allowedDemoActions.push(generateSetReserveActionData(claimCreationDate, reserve));
        });
      }

      if (actionFlagEnabledMapping[AI_ACTION_TYPES.COMMUNICATE]) {
        allowedDemoActions.push(generateCommunicationActionData(claimCreationDate));
      }

      return allowedDemoActions;
    },
    [actionFlagEnabledMapping, claim.exposures]
  );

  const addDemoActionsIfNeeded = React.useCallback(
    (handlingAdjuster: string, reserves: ReserveActionData[]): AiAction[] | undefined => {
      let freshDemoActions = undefined as AiAction[] | undefined;
      if (shouldBuildDemoActions) {
        // build demo actions once and store them in the state
        freshDemoActions = generateStaticDemoActions(handlingAdjuster, reserves, new Date(claim.creation_date));
        setDemoActions(freshDemoActions);
        setShouldBuildDemoActions(false);
      }
      return freshDemoActions;
    },
    [shouldBuildDemoActions, generateStaticDemoActions, claim.creation_date]
  );

  const fetchActions = useCallback(async () => {
    if (isActionsTabEnabled) {
      try {
        setIsLoading(true);
        const url = `/api/v1/claims/${claim.id}/actions${shouldBuildDemoActions ? '?additional_data=true' : ''}`;
        const res = await axios.get(url);
        const freshDemoActions = addDemoActionsIfNeeded(res.data?.first_handling_adjuster_name, res.data?.reserves);

        // add demo actions only if there are regular actions - a requirement from product
        const actions = res.data?.actions
          ? [...(freshDemoActions || demoActions || []), ...(res.data?.actions || [])]
          : [];
        setActions(actions);
      } catch {
        setIsError(true);
        // Re-throw to trigger failure tracking
        throw new Error('Failed to fetch Actions');
      } finally {
        setIsLoading(false);
      }
    }
  }, [claim.id, isActionsTabEnabled, demoActions, shouldBuildDemoActions, addDemoActionsIfNeeded]);

  usePollingWithFailureTracking(fetchActions, {
    pollingDelay: POLLING_DELAY,
    enabled: isActionsTabEnabled,
  });

  useEffect(() => {
    // this useEffect should run only once when the component is mounted
    fetchActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ActionsContext.Provider value={{ actions, isLoading, isError, reloadData: fetchActions }}>
      {children}
    </ActionsContext.Provider>
  );
};

export const useActions = (): IActionsContextData => {
  return React.useContext(ActionsContext);
};
