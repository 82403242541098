import LANGUAGES_DICT from '~/server_shared/generated-types/LANGUAGES_DICT';

import type { CommunicationAttachment } from './types';

export const getAttachmentFileUrl = (attachment: CommunicationAttachment, channel: 'video' | 'emails'): string => {
  return !attachment.document
    ? `/api/v1/communications/${channel}/${attachment.communication_id}/attachments/${attachment.id}/stored_file`
    : `/api/v1/claims/${attachment.document.claim_id}/documents/${attachment.document.id}`;
};

export const getLanguageName = (languageAndLocalCode?: string): string | null => {
  if (!languageAndLocalCode) return null;

  const languageCode = languageAndLocalCode.split('-')[0];
  return LANGUAGES_DICT[languageCode as keyof typeof LANGUAGES_DICT];
};
