import React from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import axios from 'axios';

import ViewCommunicationCardContainer from '~/components/communications/ViewCommunicationCardContainer';
import { useFnolAiInbox } from '~/components/Fnol/FnolAiInbox/hooks/useFnolAiInbox';
import { useFnolAiPermissions } from '~/components/Fnol/FnolAiInbox/hooks/useFnolAiPermissions';
import FnolAiInboxTable from '~/components/Fnol/FnolAiInbox/table/FnolAiInboxTable';
import { FnolAiInboxCommunicationFooter } from '~/components/Fnol/FnolAiInbox/tabs/FnolAiInboxCommunicationFooter';
import type {
  FnolAiInboxRecord,
  FnolDraft,
  InboxType,
  OnNewLossCallback,
  Policy,
} from '~/components/Fnol/FnolAiInbox/types';
import LoadingIndicator from '~/components/LoadingIndicator';
import { reportAxiosError } from '~/Utils';

interface FnolAiInboxTabsLayoutProps {
  inboxType: InboxType;
  onNewLoss?: ({ fnolDraft, communicationId, fnolAiRecommendationId, subOrgId, lob }: OnNewLossCallback) => void;
}

interface FnolAiInboxDraftResponse {
  fnol_draft: FnolDraft;
}

export const FnolAiInboxTab: React.FC<FnolAiInboxTabsLayoutProps> = ({ inboxType, onNewLoss }) => {
  const { inboxItems, isLoading, isError, reloadInboxItems } = useFnolAiInbox({ inboxType });
  const { isFnolAiInboxPolicyEnabled } = useFnolAiPermissions();
  const [selectedInboxItem, setSelectedInboxItem] = React.useState<FnolAiInboxRecord>();
  const [showAttachToClaim, setShowAttachToClaim] = React.useState(false);
  const toggleSelectedInboxItem = React.useCallback(
    (row) => {
      const unselect = selectedInboxItem?.id === row.id;
      const newVal = unselect ? undefined : row;
      setSelectedInboxItem(newVal);
    },
    [selectedInboxItem]
  );

  const onCreateNewLoss = React.useCallback(async () => {
    if (!selectedInboxItem || !onNewLoss) return;

    let fnol_draft;
    try {
      if (selectedInboxItem.status === 'new') {
        // fetching the generated fnol_draft from the BE, based on the fnol_ai_recommendation
        const { data } = await axios.post<FnolAiInboxDraftResponse>(
          `/api/v1/claims/fnol_ai/inbox/${selectedInboxItem.id}/draft`
        );
        fnol_draft = data?.fnol_draft;
      }

      onNewLoss({
        fnolDraft: fnol_draft,
        communicationId: selectedInboxItem.email_communication_id,
        fnolAiRecommendationId: selectedInboxItem.id,
        subOrgId: selectedInboxItem.sub_organization_id,
        lob: selectedInboxItem.line_of_business,
      });
    } catch (error) {
      reportAxiosError(error);
    }
  }, [selectedInboxItem, onNewLoss]);

  const onOpenDraft = React.useCallback(async () => {
    if (!selectedInboxItem || !onNewLoss) return;
    try {
      const { data: fnolDraft } = await axios.get<FnolDraft>(`/api/v1/fnol_drafts/${selectedInboxItem.draft_id}`);
      onNewLoss({
        fnolDraft,
        communicationId: selectedInboxItem.email_communication_id,
        fnolAiRecommendationId: selectedInboxItem.id,
        subOrgId: selectedInboxItem.sub_organization_id,
        lob: selectedInboxItem.line_of_business,
      });
      reloadInboxItems();
    } catch (error) {
      reportAxiosError(error);
    }
  }, [selectedInboxItem, onNewLoss, reloadInboxItems]);

  const onDismissClick = React.useCallback(async () => {
    if (!selectedInboxItem) return;
    try {
      await axios.post<FnolDraft>(`/api/v1/claims/fnol_ai/inbox/${selectedInboxItem.id}/dismiss`);
      reloadInboxItems();
      setSelectedInboxItem(undefined);
    } catch (error) {
      reportAxiosError(error);
    }
  }, [selectedInboxItem, reloadInboxItems]);

  const onOpenBack = React.useCallback(async () => {
    if (!selectedInboxItem) return;
    try {
      await axios.post<FnolDraft>(`/api/v1/claims/fnol_ai/inbox/${selectedInboxItem.id}/re_open`);
      setSelectedInboxItem(undefined);
      reloadInboxItems();
    } catch (error) {
      reportAxiosError(error);
    }
  }, [selectedInboxItem, reloadInboxItems]);

  const onCloseCommunicationCard = React.useCallback(() => {
    setSelectedInboxItem(undefined);
    setShowAttachToClaim(false);
    reloadInboxItems();
  }, [reloadInboxItems]);

  const handleAttachToExistingClaim = async (claim_id: number, attachExtraParams: Record<string, unknown>) => {
    if (!selectedInboxItem) return;
    try {
      await axios.post(`/api/v1/claims/fnol_ai/inbox/${selectedInboxItem.id}/attach_to_existing_claim`, {
        claim_id,
        ...attachExtraParams,
      });
      setShowAttachToClaim(false);
      setSelectedInboxItem(undefined);
      await reloadInboxItems();
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const handlePolicyChange = React.useCallback(
    async (inboxItemId: string, policy?: Policy) => {
      if (!inboxItemId) return;
      try {
        await axios.patch(`/api/v1/claims/fnol_ai/inbox/${inboxItemId}`, {
          policy: policy
            ? {
                policy_number: policy.policy_number,
                policy_data: policy,
              }
            : null,
        });
        reloadInboxItems();
      } catch (error) {
        reportAxiosError(error);
      }
    },
    [reloadInboxItems]
  );

  return (
    <PanelGroup direction="horizontal" id="group-claim-communications">
      <Panel id="fnol-inbox-table-display" defaultSize={70} className="max-h-[75vh]">
        <>
          {(isLoading || isError) && <LoadingIndicator isError={isError} size={24} />}
          {!isLoading && !isError && (
            <div className="mb-auto max-h-[75vh] w-full shrink overflow-y-auto">
              <FnolAiInboxTable
                rows={inboxItems}
                onRowClick={toggleSelectedInboxItem}
                selectedItemId={selectedInboxItem?.id}
                withClaimColumn={inboxType === 'done'}
                withPolicyColumn={isFnolAiInboxPolicyEnabled}
                onPolicyChange={handlePolicyChange}
              />
            </div>
          )}
        </>
      </Panel>

      {selectedInboxItem && (
        <>
          <PanelResizeHandle id="resize-handle" className="mx-12 w-2 rounded-lg bg-slate-500" />
          <Panel
            id="communication-display"
            className="relative max-h-[75vh] w-full"
            style={{ overflow: 'auto' }}
            minSize={35}
            maxSize={80}
          >
            <div className="flex h-full flex-col justify-between">
              <div>
                <div>
                  <ViewCommunicationCardContainer
                    key={selectedInboxItem.email_communication_id}
                    communicationId={selectedInboxItem.email_communication_id}
                    onClose={onCloseCommunicationCard}
                    disableAdditionalActions
                    displayAttachClaim={showAttachToClaim}
                    isDialog={showAttachToClaim}
                    onAttach={handleAttachToExistingClaim}
                  />
                </div>
              </div>
              <div className="shadow-inner sticky bottom-0 flex items-center justify-center bg-white p-8">
                <FnolAiInboxCommunicationFooter
                  inboxType={inboxType}
                  selectedInboxItem={selectedInboxItem}
                  onOpenDraft={onOpenDraft}
                  onDismissClick={onDismissClick}
                  onCreateNewLoss={onCreateNewLoss}
                  onOpenBack={onOpenBack}
                  onAttachToClaim={() => setShowAttachToClaim(true)}
                />
              </div>
            </div>
          </Panel>
        </>
      )}
    </PanelGroup>
  );
};
