import React, { useEffect, useState } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import { isEmpty, keyBy } from 'lodash';

import useFetchAdjusters from '~/Adjuster/useFetchAdjusters';
import type { NotificationsType } from '~/components/ClaimNotificationsCard/types';
import { MainCard } from '~/components/core';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import DatePickerField from '~/components/core/Molecules/Fields/DatePickerField';
import MultiSelectField from '~/components/core/Molecules/Fields/MultiSelectField';
import LabeledValue from '~/components/core/Molecules/LabledValue';
import { useCms } from '~/components/hooks/useCms';
import type { NotificationTypeCount } from '~/components/Notifications/types';
import type { PossibleNotificationTypeById } from '~/components/Notifications/utils';
import { mapPossibleNotificationsTypesToIds } from '~/components/Notifications/utils';
import useOrganization from '~/components/OrganizationContext';
import type { CategoryModel } from '~/components/SystemConfiguration/NotificationsConfiguration/NotificationsCategories';
import SubOrganizationMultiSelect from '~/components/TPA/SubOrganizations/SubOrganizationMultiSelect';
import CLAIM_NOTIFICATIONS_PRIORITY from '~/server_shared/generated-types/CLAIM_NOTIFICATIONS_PRIORITY';
import { NOTIFICATION_STATUSES } from '~/Types';

import CloseIcon from '../icons/CloseIcon';

export interface NotificationsFiltersValues {
  sub_organization_ids: number[];
  categories_ids: string[];
  statuses: (keyof typeof NOTIFICATION_STATUSES)[];
  types: (NotificationsType | 'All')[];
  priorities: (keyof typeof CLAIM_NOTIFICATIONS_PRIORITY)[];
  start_due_date: string | null;
  end_due_date: string | null;
  adjusters_ids: string[];
}

interface NotificationsFiltersProps {
  onSearch: (currentFields: NotificationsFiltersValues) => void;
  isUpdating: boolean;
  typesCounts: NotificationTypeCount[];
  defaultAssignee: string[];
}

const NotificationsFilters: React.FC<NotificationsFiltersProps> = ({
  onSearch,
  isUpdating,
  typesCounts,
  defaultAssignee,
}) => {
  const { organizationCategories, isLoadingCategories, isErrorCategories } = useOrganization() as {
    organizationCategories: CategoryModel[];
    isLoadingCategories: boolean;
    isErrorCategories: boolean;
  };
  const categoriesById = keyBy(organizationCategories, 'id');

  const { user } = useCms();
  const { isLoadingPossibleAdjusters, isErrorPossibleAdjusters, possibleAdjustersWithRemoved } = useFetchAdjusters(
    user.organization_id
  );

  const [possibleTypes, setPossibleTypes] = useState<NotificationsType[]>([]);
  const [notificationTypeToTypeConfig, setNotificationTypeToTypeConfig] = useState<{
    [id: string]: PossibleNotificationTypeById;
  }>({});
  const [selectedSubOrgIds, setSelectedSubOrgIds] = useState<number[]>([]);
  const [selectedCategoriesIds, setSelectedCategoriesIds] = useState<string[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<(keyof typeof NOTIFICATION_STATUSES)[]>([]);
  const [selectedTypes, setSelectedTypes] = useState<(NotificationsType | 'All')[]>([]);
  const [selectedPriorities, setSelectedPriorities] = useState<(keyof typeof CLAIM_NOTIFICATIONS_PRIORITY)[]>([]);
  const [selectedStartDueDate, setSelectedStartDueDate] = useState<string | null>(null);
  const [selectedEndDueDate, setSelectedEndDueDate] = useState<string | null>(null);
  const [selectedAdjustersIds, setSelectedAdjustersIds] = useState<string[]>(defaultAssignee);

  const allowedAdjusters = possibleAdjustersWithRemoved.filter(
    (adjuster) =>
      user.is_org_level_supervisor ||
      user.shared_notifications_user_ids?.includes(adjuster.id) ||
      user.id === adjuster.id
  );
  const adjustersById = keyBy(allowedAdjusters, 'id');

  useEffect(() => {
    if (!selectedAdjustersIds.length && possibleAdjustersWithRemoved.length > 0 && user) {
      setSelectedAdjustersIds(
        possibleAdjustersWithRemoved
          .filter((adjuster) => adjuster.id === user.id)
          .map((allowedAdjuster) => allowedAdjuster.id)
      );
    }
  }, [possibleAdjustersWithRemoved, user, selectedAdjustersIds]);

  useEffect(() => {
    const { sortedIds, map } = mapPossibleNotificationsTypesToIds(typesCounts);
    setNotificationTypeToTypeConfig(map);
    setPossibleTypes(sortedIds);
  }, [typesCounts]);

  useEffect(() => {
    if (!isLoadingPossibleAdjusters && !isErrorPossibleAdjusters && possibleAdjustersWithRemoved) {
      onSearch({
        sub_organization_ids: selectedSubOrgIds,
        categories_ids: selectedCategoriesIds,
        statuses: selectedStatuses,
        types: selectedTypes,
        priorities: selectedPriorities,
        start_due_date: selectedStartDueDate,
        end_due_date: selectedEndDueDate,
        adjusters_ids: selectedAdjustersIds.includes('All') ? [] : selectedAdjustersIds,
      });
    }
  }, [
    onSearch,
    selectedSubOrgIds,
    selectedCategoriesIds,
    selectedStatuses,
    selectedTypes,
    selectedPriorities,
    selectedStartDueDate,
    selectedEndDueDate,
    selectedAdjustersIds,
    isLoadingPossibleAdjusters,
    isErrorPossibleAdjusters,
    possibleAdjustersWithRemoved,
  ]);

  return (
    <div className="mb-12">
      <MainCard type="filled" collapsible title="Filter" openByDefault={false}>
        <div className="grid grid-cols-3 gap-x-20 gap-y-32">
          <SubOrganizationMultiSelect onChange={setSelectedSubOrgIds} disabled={isUpdating} />
          <div>
            <MultiSelectField<string>
              id="categories"
              label="Category"
              options={Object.keys(categoriesById)}
              disabled={isUpdating || isLoadingCategories || isErrorCategories}
              renderValue={(selectedCategoriesIds) => {
                return selectedCategoriesIds
                  .map((selectedCategoryId) => categoriesById[selectedCategoryId].label)
                  .join(', ');
              }}
              renderOption={(categoryId) => categoriesById[categoryId].label}
              fullWidth
              sortAlphabetic
              onChange={setSelectedCategoriesIds}
              value={selectedCategoriesIds}
              InputProps={{
                endAdornment: isEmpty(selectedCategoriesIds) ? null : (
                  <InputAdornment className="mr-24" position="start">
                    <IconButton className="p-8" onClick={() => setSelectedCategoriesIds([])}>
                      <CloseIcon size={10} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div>
            <MultiSelectField<keyof typeof NOTIFICATION_STATUSES>
              id="status"
              label="Status"
              // TODO: after NGTPA-17201
              // options={Object.keys(NOTIFICATION_STATUSES) as (keyof typeof NOTIFICATION_STATUSES)[]}
              options={['overdue']}
              disabled={isUpdating}
              renderValue={(selectedStatuses: (keyof typeof NOTIFICATION_STATUSES)[]) => {
                return selectedStatuses.map((selectedStatus) => NOTIFICATION_STATUSES[selectedStatus] || '').join(', ');
              }}
              renderOption={(statusKey: keyof typeof NOTIFICATION_STATUSES) => {
                return NOTIFICATION_STATUSES[statusKey] || '';
              }}
              fullWidth
              sortAlphabetic
              onChange={setSelectedStatuses}
              value={selectedStatuses}
              InputProps={{
                endAdornment: isEmpty(selectedStatuses) ? null : (
                  <InputAdornment className="mr-24" position="start">
                    <IconButton className="p-8" onClick={() => setSelectedStatuses([])}>
                      <CloseIcon size={10} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div>
            <MultiSelectField<NotificationsType | 'All', 'All'>
              id="type"
              label="Types"
              value={selectedTypes}
              onChange={(value) => (value.includes('All') ? setSelectedTypes([]) : setSelectedTypes(value))}
              renderValue={(selectedTypes) =>
                selectedTypes.map((id) => notificationTypeToTypeConfig[id].label).join(', ')
              }
              renderOption={(possibleNotificationTypeId) => {
                const possibleNotificationType = notificationTypeToTypeConfig[possibleNotificationTypeId];
                return (
                  <div className="flex items-center gap-12">
                    {possibleNotificationType?.icon}
                    <LabeledValue
                      label={possibleNotificationType?.label}
                      value={possibleNotificationType?.subtype && possibleNotificationType.subtype.replace('_', ' ')}
                    />
                  </div>
                );
              }}
              options={possibleTypes}
              addAllOption
              allOptionValue="All"
              fullWidth
            />
          </div>
          <div>
            <MultiSelectField<keyof typeof CLAIM_NOTIFICATIONS_PRIORITY>
              id="priority"
              label="Priority"
              options={Object.keys(CLAIM_NOTIFICATIONS_PRIORITY) as (keyof typeof CLAIM_NOTIFICATIONS_PRIORITY)[]}
              disabled={isUpdating}
              renderValue={(selectedPriorityKeys: (keyof typeof CLAIM_NOTIFICATIONS_PRIORITY)[]) => {
                return selectedPriorityKeys
                  .map((selectedPriorityKey) => CLAIM_NOTIFICATIONS_PRIORITY[selectedPriorityKey] || '')
                  .join(', ');
              }}
              renderOption={(priorityKey: keyof typeof CLAIM_NOTIFICATIONS_PRIORITY) => {
                return CLAIM_NOTIFICATIONS_PRIORITY[priorityKey] || '';
              }}
              fullWidth
              sortAlphabetic
              onChange={setSelectedPriorities}
              value={selectedPriorities}
              InputProps={{
                endAdornment: isEmpty(selectedPriorities) ? null : (
                  <InputAdornment className="mr-24" position="start">
                    <IconButton className="p-8" onClick={() => setSelectedPriorities([])}>
                      <CloseIcon size={10} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div>
            <DatePickerField
              value={selectedStartDueDate}
              onChange={(date) => setSelectedStartDueDate(date ? date.format('YYYY-MM-DD') : null)}
              label="Start Due Date"
              disableFuture
              fullWidth
              clearable
            />
          </div>
          <div>
            <DatePickerField
              value={selectedEndDueDate}
              onChange={(date) => setSelectedEndDueDate(date ? date.format('YYYY-MM-DD') : null)}
              label="End Due Date"
              disableFuture
              fullWidth
              clearable
            />
          </div>
          {!isLoadingPossibleAdjusters && !isErrorPossibleAdjusters && allowedAdjusters.length > 0 && (
            <div>
              <MultiSelectField<string, 'All'>
                id="adjusters_ids"
                label="Assignee"
                options={Object.keys(adjustersById)}
                disabled={isUpdating}
                renderValue={(selectedAdjustersIds) => {
                  return selectedAdjustersIds
                    .map((selectedAdjusterId) =>
                      selectedAdjusterId === 'All' ? 'All' : adjustersById[selectedAdjusterId].username
                    )
                    .join(', ');
                }}
                renderOption={(adjusterId) => adjustersById[adjusterId].username}
                fullWidth
                sortAlphabetic
                onChange={setSelectedAdjustersIds}
                value={selectedAdjustersIds}
                addAllOption
                allOptionValue="All"
                InputProps={{
                  endAdornment: isEmpty(selectedAdjustersIds) ? null : (
                    <InputAdornment className="mr-24" position="start">
                      <IconButton className="p-8" onClick={() => setSelectedAdjustersIds([])}>
                        <CloseIcon size={10} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          )}
        </div>
      </MainCard>
    </div>
  );
};

export default NotificationsFilters;
