import { isEmpty } from 'lodash';
import * as Yup from 'yup';

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-_.]+(?:\.[a-zA-Z0-9-]+)*$/;

export const emailCommunicationToFormikValues = (communication) => ({
  summary: communication.summary,
  subject: communication.subject,
  body_html: communication.body_html,
  body_text: communication.body_text,
  to_contacts: communication.to_contacts,
  cc_contacts: communication.cc_contacts,
  bcc_contacts: communication.bcc_contacts,
  translated_body_html: communication.translated_body_html,
});

// This is relevant only for Reply to email not attached to a claim. All other fields are ignored in that case
export const getEmailMessageNotAttachedToClaimValidation = () =>
  Yup.object().shape({
    subject: Yup.string().required('Required'),
    body_html: Yup.string().required('Required'),
    summary: Yup.string(),
  });

export const getEmailMessageValidation = (direction, isNotClaimRelated) =>
  Yup.object().shape({
    contact_id: Yup.number().required('Required'),
    contact: contactValidationSchema,
    cc_contacts: Yup.array().of(recipientContactValidationScheme),
    bcc_contacts: Yup.array().of(recipientContactValidationScheme),
    subject: Yup.string().required('Required'),
    body_html: direction === 'Outgoing' ? Yup.string().required('Required') : undefined,
    add_follow_up_reminder: !isNotClaimRelated ? Yup.boolean() : undefined,
    attached_documents: !isNotClaimRelated
      ? Yup.array().test(
          'is-attachments-size-valid',
          'Attachments size can not be bigger than 22MB',
          (value) => value.length === 0 || value.reduce((acc, curr) => acc + curr.size, 0) < 22 * 1024 * 1024
        )
      : undefined,
    email_id: Yup.number().required('Required'),
    exposure_ids: !isNotClaimRelated ? Yup.array().required('Required').min(1, 'Required') : undefined,
    summary: Yup.string(),
  });

const contactValidationSchema = Yup.object()
  .test('is-email-exists', 'Contact must contain at least one email address', (contact) => !isEmpty(contact?.emails))
  .test(
    'is-email-valid',
    ({ originalValue }) =>
      originalValue?.emails?.length === 1
        ? 'Provided email address is not valid'
        : 'Selected email address is not valid',
    (contact, context) => {
      const selectedEmail = contact?.emails?.find((email) => email.id === context.parent?.email_id);
      return selectedEmail ? EMAIL_REGEX.test(selectedEmail.email) : true;
    }
  )
  .test(
    'is-emailing-allowed',
    'Sending emails to this contact was marked as not allowed',
    (contact) => contact && contact.is_emailing_allowed !== false
  )
  .test(
    'is-email-not-empty',
    'Invalid email',
    (contact) => contact && !contact.emails.find((emailObject) => emailObject.email === undefined)
  );

export const recipientContactValidationScheme = Yup.object().shape({
  contact_id: Yup.number().required('Required').typeError('Required'),
  contact: contactValidationSchema,
  email_id: Yup.number().required('Required').typeError('Required'),
});
