import * as Sentry from '@sentry/react';
import type { AxiosError } from 'axios';
import axios from 'axios';
import StackTrace from 'stacktrace-js';

import { openTopLevelDialog } from '~/components/CmsMain/globals';
import { getPermissionDeniedDialogConfig } from '~/components/core/PermissionError/PermissionError';
import { extractError } from '~/Utils/errors/extractError';
import { isPermissionDeniedError } from '~/Utils/errors/permissionDenined';

import type { ErrorJson } from './types';

export const reportAxiosErrorBase = async (
  error: AxiosError,
  openDialogError = true
): Promise<ErrorJson | undefined> => {
  let errorJson: ErrorJson = {};

  if (openDialogError) {
    if (isPermissionDeniedError(error)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      openTopLevelDialog(getPermissionDeniedDialogConfig(error));
      return; // Don't report error
    }
    const { title, message, reportError } = extractError(error);

    openTopLevelDialog({ title, message });
    if (reportError) {
      Sentry.captureException(error);
    }
  }

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    errorJson = {
      type: 'axios_response',
      status: error.response.status,
      data: error.response.data,
      headers: error.response.headers,
    };
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest
    errorJson = {
      type: 'axios_request',
      request: error.request as XMLHttpRequest,
    };
  } else {
    // Something happened in setting up the request that triggered an Error
    errorJson = {
      type: 'Other',
      name: error.name?.toString(),
      stack: error.stack?.toString(),
      message: error.message,
    };
    // eslint-disable-next-line no-console
    console.log(error.stack?.toString());
  }

  errorJson = { ...errorJson, config: error.config };

  let stackTracePromise: Promise<StackTrace.StackFrame[]> | undefined;
  if (errorJson.type === 'Other') {
    stackTracePromise = StackTrace.fromError(error);
  } else if (errorJson.type !== 'axios_response') {
    stackTracePromise = StackTrace.get();
  }

  if (stackTracePromise) {
    const stackFrames = await stackTracePromise;
    errorJson.stackParsed =
      'Error\n' +
      stackFrames.map((sf) => `at ${sf.functionName} (${sf.fileName}:${sf.lineNumber}:${sf.columnNumber})`).join('\n');
  }

  // Note: we intentionally don't check for failure of the axios.post since the original exception might have been caused by a network error
  // (and we don't want a weird recursion)
  await axios.post('/api/v1/errors', errorJson);

  return errorJson;
};
