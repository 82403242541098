import { useContext } from 'react';
import type { FormikContextType } from 'formik';
import { FormikContext } from 'formik';
import { isEmpty } from 'lodash';

/**
 * This hook is needed because useFormikContext warns on 'Formik context is undefined' by default
 * See: https://github.com/jaredpalmer/formik/blob/main/packages/formik/src/FormikContext.tsx
 **/
const useSafeFormikContext = <Values,>(
  warnOnOutOfContext = false
): FormikContextType<Values> | Record<string, unknown> => {
  const formikContext = useContext(FormikContext) || {};

  if (warnOnOutOfContext && isEmpty(formikContext)) {
    // eslint-disable-next-line no-console
    console.warn(
      'Formik context is undefined with warnOnOutOfContext true, please verify you are calling useSafeFormikContext() as child of a <Formik> component.'
    );
  }

  return formikContext;
};

export default useSafeFormikContext;
