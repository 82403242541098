import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import propTypes from 'prop-types';
import { Menu } from '@material-ui/core';
import axios from 'axios';
import { isEmpty, noop } from 'lodash';

import { useStyles } from '~/assets/styles';
import ForwardEmailCommunicationBaseDialog from '~/components/communications/EmailCommunicationCard/Forward/ForwardEmailCommunicationBaseDialog';
import ReplyEmailCommunicationBaseContainer from '~/components/communications/EmailCommunicationCard/Replay/ReplyEmailCommunicationBaseContainer';
import ReplySmsCommunicationDialog from '~/components/communications/SmsCommunicationCard/ReplySmsCommunicationDialog';
import { useMinimizedDialogs } from '~/components/core/MinimizedBar/Context';
import { useHasPermission } from '~/components/hooks/useHasPermission';
import {
  AttachIcon,
  DetachIcon,
  ForwardIcon,
  OptionsIcon,
  ReferIcon,
  ReplyAllIcon,
  ReplyIcon,
  TrashIcon_Deprecated,
} from '~/components/icons';
import { MIXPANEL_EVENT_SOURCES, MIXPANEL_EVENTS } from '~/pocs/mixpanel';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isUserClaimPrivileged } from '~/UserUtils';
import { isFeatureEnabled, reportAxiosError } from '~/Utils';

import mixpanel from './CmsMain/mixpanel';
import AttachCommunicationContainer from './communications/AttachCommunicationContainer';
import { isPermissionsEnabled, PERMISSION_ACTIONS, PERMISSION_VERBS } from './core/Permissions/PermissionUtils';
import { useCms } from './hooks/useCms';
import MailIcon from './icons/MailIcon';
import CardDialog from './CardDialog';
import { useClaim } from './ClaimContainer';
import WithConfirm from './ConfirmModal';
import { FsIconButton, FsMenuItem } from './core';
import { AddInternalCommunicationBaseContainer, ReferMailToUserDialog } from './InternalCommunication';
import useOrganization from './OrganizationContext';

const CommunicationActionsMenu = ({
  communication,
  onUpdate = noop,
  onDelete = noop,
  onRefer = noop,
  onAttach,
  onForward = undefined,
  onReply = undefined,
  displayGeneralQueueActions = false,
  displayDetachAction = false,
  displayNotificationAction = false,
  displayEmailCommunicationAction = false,
}) => {
  const { user, userOrganization } = useCms();
  const classes = useStyles();
  const { inClaimPage } = useClaim();
  const { orgAdditionalConfigs } = useOrganization();
  const history = useHistory();
  const { add } = useMinimizedDialogs();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showAttachToClaim, setShowAttachToClaim] = useState(false);
  const [showReferEmail, setShowReferEmail] = useState(false);
  const [showAddInternalCommunication, setShowAddInternalCommunication] = useState(false);
  const [showReplyToSms, setShowReplyToSms] = useState(false);
  const isNotifications2Enabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.NOTIFICATIONS_2);

  const EMAIL_TYPE = 'EMAIL';

  const isClaimCommunicationWithoutContact =
    communication?.claim_id && !(communication?.contact_id || communication?.contact);

  const userHasEmailClassificationPermissions = useHasPermission({
    action: PERMISSION_ACTIONS.GENERAL_EMAIL_CLASSIFICATION,
    verb: PERMISSION_VERBS.WRITE,
  });
  const allowedToPerformClassificationAction =
    displayGeneralQueueActions &&
    (isPermissionsEnabled(userOrganization)
      ? userHasEmailClassificationPermissions
      : orgAdditionalConfigs?.email_classifier_user_ids?.includes(user.id));

  const isSms = communication?.channel === 'sms';

  const deleteEmail = async (communication) => {
    try {
      await onDelete(communication);
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
    }
  };

  const detachClaim = async (communication) => {
    try {
      await axios.post(
        `/api/v1/claims/${communication.claim_id}/communications/${communication.id}/detach_communication`
      );
      if (inClaimPage) {
        history.push(`/communications/${communication.id}`);
      } else if (onUpdate) {
        await onUpdate();
      }
    } catch (error) {
      reportAxiosError(error);
    }
  };

  const closeAttachToClaim = () => {
    setShowAttachToClaim(false);
  };

  const handleAttachAndClose = async (claim_id, attachExtraParams) => {
    try {
      if (onAttach) {
        await onAttach(claim_id, attachExtraParams);
      } else {
        await axios.post(`/api/v1/communications/${communication.id}/attach`, {
          claim_id,
          ...attachExtraParams,
        });
      }
      await onUpdate();
      closeAttachToClaim();
    } catch (error) {
      reportAxiosError(error);
    }
  };

  const handleOpenReplyToEmailDialog = () => {
    const { handleCloseDialog } = add({
      barHeader: `Re: ${communication.subject}`,
      type: EMAIL_TYPE,
      dialogComponent: ReplyEmailCommunicationBaseContainer,
      dialogProps: {
        communication,
        onReply: allowedToPerformClassificationAction ? handleReply : undefined,
        overrideShowWarning: !allowedToPerformClassificationAction,
        shouldDisplaySenderEmail: !allowedToPerformClassificationAction,
        displayGeneralQueueActions,
        onMinimized: () => {
          mixpanel.track(MIXPANEL_EVENTS.MINIMIZED_DIALOG, {
            source: MIXPANEL_EVENT_SOURCES.COMMUNICATIONS_ACTIONS_MENU_REPLY,
            type: EMAIL_TYPE,
          });
        },
        onClose: () => handleCloseDialog(),
      },
    });
  };

  const handleOpenReplyToAllEmailDialog = () => {
    const { handleCloseDialog } = add({
      barHeader: `Reply All: ${communication.subject}`,
      type: EMAIL_TYPE,
      dialogComponent: ReplyEmailCommunicationBaseContainer,
      dialogProps: {
        communication,
        onReply: allowedToPerformClassificationAction ? handleReply : undefined,
        overrideShowWarning: !allowedToPerformClassificationAction,
        replyAll: true,
        displayGeneralQueueActions,
        onMinimized: () => {
          mixpanel.track(MIXPANEL_EVENTS.MINIMIZED_DIALOG, {
            source: MIXPANEL_EVENT_SOURCES.COMMUNICATIONS_ACTIONS_MENU_REPLY_ALL,
            type: EMAIL_TYPE,
          });
        },
        onClose: async () => {
          try {
            onUpdate();
            handleCloseDialog();
          } catch (error) {
            await reportAxiosError(error);
          }
        },
      },
    });
  };

  const handleOpenForwardEmailDialog = () => {
    const { handleCloseDialog } = add({
      barHeader: `Fwd: ${communication.subject}`,
      type: EMAIL_TYPE,
      dialogComponent: ForwardEmailCommunicationBaseDialog,
      dialogProps: {
        communication,
        onForward: !allowedToPerformClassificationAction ? undefined : handleForward,
        onSendEmail: allowedToPerformClassificationAction ? undefined : handleForward,
        shouldDisplaySenderEmail: !allowedToPerformClassificationAction,
        isGeneralQueueEmail: displayGeneralQueueActions,
        onMinimized: () => {
          mixpanel.track(MIXPANEL_EVENTS.MINIMIZED_DIALOG, {
            source: MIXPANEL_EVENT_SOURCES.COMMUNICATIONS_ACTIONS_MENU_FORWARD,
            type: EMAIL_TYPE,
          });
        },
        onClose: () => handleCloseDialog(),
      },
    });
  };

  const handleClickOnNewInternalCommunication = () => {
    const { handleCloseDialog } = add({
      barHeader: `Send Internal Communication`,
      type: 'INTERNAL_COMMUNICATION',
      dialogComponent: AddInternalCommunicationBaseContainer,
      dialogProps: {
        icObject: { type: 'communication', communication },
        onMinimized: () => {
          mixpanel.track(MIXPANEL_EVENTS.MINIMIZED_DIALOG, {
            source: MIXPANEL_EVENT_SOURCES.COMMUNICATIONS_ACTIONS_MENU_INTERNAL_COMMUNICATION,
            type: 'INTERNAL_COMMUNICATION',
          });
        },
        onClose: () => handleCloseDialog(),
      },
    });
  };

  const handleMarkAsUnread = async () => {
    try {
      await axios.post(`/api/v1/communications/${communication.id}/mark_as_unread`);
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
    }
  };

  const handleForward = async (values) => {
    if (onForward) {
      await onForward(values);
    }
    await onUpdate();
  };

  const handleReply = async (values) => {
    if (onReply) {
      await onReply(values);
    }
    await onUpdate();
  };

  const handleReferToUser = async (values) => {
    try {
      onRefer && (await onRefer(values));
      await onUpdate();
      closeReferEmail();
    } catch (error) {
      reportAxiosError(error);
    }
  };

  const closeReferEmail = () => {
    setShowReferEmail(false);
  };

  const menuItemWithIconStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    alignContent: 'center',
    width: '100%',
  };

  const deleteConfirmProps = {
    title: 'Are you sure?',
    contentText: 'This action will delete the email from this queue',
    primaryButtonName: 'OK',
    disableConfirm: false,
  };

  const isAdjusterSystemUser = communication.is_adjuster_system_user;

  const detachSystemUserWarningText =
    'Detaching this email from the claim will result in it being unassigned and no longer visible in the system. Are you sure you want to proceed?';
  const detachAdjusterText = 'This will delete the communication from the claim';

  const detachConfirmProps = {
    title: 'Detach communication?',
    contentText: isAdjusterSystemUser ? detachSystemUserWarningText : detachAdjusterText,
    primaryButtonName: 'Yes',
    shouldCloseOnPrimary: !inClaimPage,
    disableConfirm: false,
  };

  const handleClickOnReply = () => {
    mixpanel.track(MIXPANEL_EVENTS.NEW_EMAIL_COMMUNICATION_CLICKED, {
      source: MIXPANEL_EVENT_SOURCES.COMMUNICATIONS_ACTIONS_MENU_REPLY,
    });

    handleOpenReplyToEmailDialog();
  };

  const handleClickOnReplyAll = () => {
    mixpanel.track(MIXPANEL_EVENTS.NEW_EMAIL_COMMUNICATION_CLICKED, {
      source: MIXPANEL_EVENT_SOURCES.COMMUNICATIONS_ACTIONS_MENU_REPLY_ALL,
    });

    handleOpenReplyToAllEmailDialog();
  };

  const handleClickOnForward = () => {
    mixpanel.track(MIXPANEL_EVENTS.NEW_EMAIL_COMMUNICATION_CLICKED, {
      source: MIXPANEL_EVENT_SOURCES.COMMUNICATIONS_ACTIONS_MENU_FORWARD,
    });
    handleOpenForwardEmailDialog();
  };

  const iconColor = '#909090';
  const menuItemContent = [
    {
      onClick: () => setShowAttachToClaim(true),
      iconComponent: <AttachIcon iconColor={iconColor} />,
      description: 'Attach to claim',
      shouldDisplay: !displayGeneralQueueActions && !communication.claim_id,
    },
    {
      onClick: () => setShowAttachToClaim(true),
      iconComponent: <AttachIcon iconColor={iconColor} />,
      description: 'Attach email to claim',
      shouldDisplay: allowedToPerformClassificationAction,
    },
    {
      onClick: () => setShowReferEmail(true),
      iconComponent: <ReferIcon iconColor={iconColor} />,
      description: 'Refer email to another user',
      shouldDisplay: allowedToPerformClassificationAction,
    },
    {
      onClick: handleClickOnReply,
      iconComponent: <ReplyIcon iconColor={iconColor} />,
      description: 'Reply',
      shouldDisplay:
        !isClaimCommunicationWithoutContact &&
        (allowedToPerformClassificationAction || (displayEmailCommunicationAction && !displayGeneralQueueActions)),
    },
    {
      onClick: () => {
        setShowReplyToSms(true);
        mixpanel.track(MIXPANEL_EVENTS.NEW_SMS_COMMUNICATION_CLICKED, {
          source: MIXPANEL_EVENT_SOURCES.REPLY_SMS_CLICKED,
        });
      },
      iconComponent: <ReplyIcon iconColor={iconColor} />,
      description: 'Reply',
      shouldDisplay:
        isSms &&
        user.is_sending_sms_allowed &&
        isUserClaimPrivileged(user) &&
        communication.claim_id &&
        !user.role.is_view_only &&
        !communication.contact_phone.is_removed,
    },
    {
      onClick: handleClickOnReplyAll,
      iconComponent: <ReplyAllIcon iconColor={iconColor} />,
      description: 'Reply All',
      shouldDisplay:
        !isClaimCommunicationWithoutContact &&
        displayEmailCommunicationAction &&
        (communication?.cc_contacts?.length > 0 || communication?.to_contacts?.length > 0),
    },
    {
      onClick: handleClickOnForward,
      iconComponent: <ForwardIcon iconColor={iconColor} />,
      description: 'Forward',
      shouldDisplay:
        !isClaimCommunicationWithoutContact &&
        (allowedToPerformClassificationAction ||
          (displayEmailCommunicationAction && !displayGeneralQueueActions && communication.claim_id)),
    },
    {
      onClick: async () => await deleteEmail(communication),
      iconComponent: <TrashIcon_Deprecated iconColor={iconColor} />,
      description: 'Delete',
      withConfirmProps: deleteConfirmProps,
      shouldDisplay: allowedToPerformClassificationAction,
    },
    {
      onClick: async () => await detachClaim(communication),
      iconComponent: <DetachIcon iconColor={iconColor} />,
      description: 'Detach from claim',
      withConfirmProps: detachConfirmProps,
      shouldDisplay: displayDetachAction,
    },
    {
      onClick: handleClickOnNewInternalCommunication,
      iconComponent: <ReferIcon className={classes.iconSize} iconColor={iconColor} />,
      description: 'New internal communication',
      shouldDisplay: displayNotificationAction,
    },
    {
      onClick: handleMarkAsUnread,
      iconComponent: <MailIcon className={classes.iconSize} iconColor={iconColor} />,
      description: 'Mark as unread',
      shouldDisplay: isNotifications2Enabled,
    },
  ];

  const displayedActions = menuItemContent.filter(({ shouldDisplay }) => shouldDisplay);

  if (isEmpty(displayedActions)) {
    return null;
  }

  return (
    <>
      <FsIconButton icon={OptionsIcon} onClick={(event) => setAnchorEl(event.currentTarget)} />
      <Menu
        PaperProps={{
          style: {
            width: 250,
          },
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {menuItemContent.map((item, index) =>
          item.shouldDisplay ? (
            <WithConfirm
              key={index}
              shouldCloseOnPrimary={true}
              disableConfirm
              title=""
              primaryButtonName=""
              onClose={() => setAnchorEl(null)}
              {...(item.withConfirmProps || {})}
            >
              <FsMenuItem
                key={index}
                style={menuItemWithIconStyle}
                onClick={() => {
                  setAnchorEl(null);
                  item.onClick();
                }}
              >
                {item.iconComponent}
                <span style={{ paddingLeft: '8px' }}>{item.description}</span>
              </FsMenuItem>
            </WithConfirm>
          ) : null
        )}
      </Menu>

      {showAttachToClaim && (
        <CardDialog noCardTitle isDialog onClose={closeAttachToClaim} maxWidth="md" fullWidth>
          <AttachCommunicationContainer
            communication={communication}
            onAttachOverride={handleAttachAndClose}
            onAttach={noop}
          />
        </CardDialog>
      )}

      {showReferEmail && (
        <ReferMailToUserDialog communication={communication} onRefer={handleReferToUser} onClose={closeReferEmail} />
      )}

      {showAddInternalCommunication && (
        <AddInternalCommunicationBaseContainer
          icObject={{ type: 'communication', communication }}
          open={showAddInternalCommunication}
          onClose={() => setShowAddInternalCommunication(false)}
        />
      )}
      {showReplyToSms && (
        <ReplySmsCommunicationDialog communication={communication} onClose={() => setShowReplyToSms(false)} />
      )}
    </>
  );
};

CommunicationActionsMenu.propTypes = {
  communication: propTypes.object.isRequired,
  onUpdate: propTypes.func,
  onDelete: propTypes.func,
  onRefer: propTypes.func,
  onAttach: propTypes.func,
  onForward: propTypes.func,
  onReply: propTypes.func,
  displayGeneralQueueActions: propTypes.bool,
  displayDetachAction: propTypes.bool,
  displayNotificationAction: propTypes.bool,
  displayEmailCommunicationAction: propTypes.bool,
};

export default CommunicationActionsMenu;
