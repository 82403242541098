import React from 'react';

import type { IconProps } from '~/components/icons/types';

import SvgIcon from '../core/SvgIcon';

const ArrowElbowRightIcon: React.FC<IconProps> = ({ iconColor, ...props }) => (
  <SvgIcon disableStrokeColor width="20" height="20" viewBox="0 0 20 20" {...props}>
    <path
      d="M18.7503 6.25V11.875C18.7503 12.0408 18.6845 12.1997 18.5673 12.3169C18.4501 12.4342 18.2911 12.5 18.1253 12.5C17.9596 12.5 17.8006 12.4342 17.6834 12.3169C17.5662 12.1997 17.5003 12.0408 17.5003 11.875V7.75859L9.81753 15.4422C9.75949 15.5003 9.69056 15.5464 9.61468 15.5779C9.53881 15.6093 9.45748 15.6255 9.37535 15.6255C9.29321 15.6255 9.21188 15.6093 9.13601 15.5779C9.06014 15.5464 8.9912 15.5003 8.93316 15.4422L1.43316 7.94219C1.31588 7.82491 1.25 7.66585 1.25 7.5C1.25 7.33415 1.31588 7.17509 1.43316 7.05781C1.55044 6.94054 1.7095 6.87465 1.87535 6.87465C2.0412 6.87465 2.20026 6.94054 2.31753 7.05781L9.37535 14.1164L16.6168 6.875H12.5003C12.3346 6.875 12.1756 6.80915 12.0584 6.69194C11.9412 6.57473 11.8753 6.41576 11.8753 6.25C11.8753 6.08424 11.9412 5.92527 12.0584 5.80806C12.1756 5.69085 12.3346 5.625 12.5003 5.625H18.1253C18.2911 5.625 18.4501 5.69085 18.5673 5.80806C18.6845 5.92527 18.7503 6.08424 18.7503 6.25Z"
      fill={iconColor}
    />
  </SvgIcon>
);

export default ArrowElbowRightIcon;
