import React from 'react';
import PropTypes from 'prop-types';

import { ClaimContextProvider } from '~/components/ClaimContainer';
import SendEmailCommunicationCardContainer from '~/components/communications/EmailCommunicationCard/SendEmailCommunicationCardContainer';
import { useCms } from '~/components/hooks/useCms';
import LoadingDialog from '~/components/LoadingDialog';
import useDataFetcher from '~/components/useDataFetcher';
import { reportAxiosError } from '~/Utils';
import { useFetchClaim } from '~/Utils/ClaimUtils';

import { useReplyEmailText } from '../hooks/useReplyEmailText';

import ReplyNonClaimEmailCommunicationDialog from './ReplyNonClaimEmailCommunicationDialog';

const ReplyEmailCommunicationBaseContainer = ({
  communication,
  replyAll = false,
  onClose,
  onReply,
  overrideShowWarning = true,
  shouldDisplaySenderEmail = true,
  onMinimized,
  disableMinimized,
  open,
}) => (
  <>
    {communication.claim_id ? (
      <ReplyEmailCommunicationDialog
        communication={communication}
        onClose={onClose}
        cc_contacts={replyAll ? (communication.cc_contacts || []).concat(communication.to_contacts || []) : undefined}
        onMinimized={onMinimized}
        disableMinimized={disableMinimized}
        open={open}
      />
    ) : (
      <ReplyNonClaimEmailCommunicationDialog
        communication={communication}
        onClose={onClose}
        onReply={
          onReply
            ? async (values) => {
                try {
                  await onReply(values);
                  onClose();
                } catch (error) {
                  await reportAxiosError(error);
                }
              }
            : null
        }
        overrideShowWarning={overrideShowWarning}
        shouldDisplaySenderEmail={shouldDisplaySenderEmail}
        onMinimized={onMinimized}
        disableMinimized={disableMinimized}
        open={open}
        ccContacts={replyAll && communication.cc_contacts ? communication.cc_contacts : undefined}
      />
    )}
  </>
);

ReplyEmailCommunicationBaseContainer.propTypes = {
  communication: PropTypes.object.isRequired,
  replyAll: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onReply: PropTypes.func,
  overrideShowWarning: PropTypes.bool,
  shouldDisplaySenderEmail: PropTypes.bool,
  onMinimized: PropTypes.func,
  disableMinimized: PropTypes.bool,
  open: PropTypes.bool,
};

const ReplyEmailCommunicationDialog = ({
  communication,
  onClose,
  cc_contacts,
  onMinimized,
  disableMinimized,
  open,
}) => {
  const { userOrganization } = useCms();

  const [claim, isLoadingClaim, isErrorClaim, reloadClaim] = useFetchClaim(communication.claim_id);
  const {
    isLoading: isLoadingContact,
    isError: isErrorContact,
    data: contactToSendEmail,
  } = useDataFetcher(`/api/v1/claims/${communication.claim_id}/contacts/${communication.contact.id}`);

  const { emailText, isLoadingEmailSignature, isErrorEmailSignature } = useReplyEmailText(
    communication,
    userOrganization
  );

  if (
    isLoadingClaim ||
    isErrorClaim ||
    isLoadingContact ||
    isErrorContact ||
    isLoadingEmailSignature ||
    isErrorEmailSignature
  ) {
    return <LoadingDialog isError={isErrorClaim || isErrorContact || isErrorEmailSignature} track="Reply Email" />;
  }

  return (
    <ClaimContextProvider claim={claim} refreshData={reloadClaim}>
      <SendEmailCommunicationCardContainer
        contact={contactToSendEmail}
        cc_contacts={cc_contacts}
        contactEmailId={
          communication.contact_email.is_removed
            ? contactToSendEmail.emails[0]?.id ?? ''
            : communication.contact_email.id
        }
        onClose={onClose}
        onSendEmail={async () => {
          await reloadClaim();
          onClose();
        }}
        emailText={emailText}
        emailTitle={`Re: ${communication.subject}`}
        referredCommunicationId={communication.id}
        communication={communication}
        onMinimized={onMinimized}
        disableMinimized={disableMinimized}
        open={open}
        shouldOverrideTitleWithTemplate={false}
        shouldContactInitialBodyWithTemplate={true}
      />
    </ClaimContextProvider>
  );
};

ReplyEmailCommunicationDialog.propTypes = {
  communication: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  cc_contacts: PropTypes.array,
  onMinimized: PropTypes.func,
  disableMinimized: PropTypes.bool,
  open: PropTypes.bool,
};

export default ReplyEmailCommunicationBaseContainer;
