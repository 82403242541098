import type { ReactElement } from 'react';
import React from 'react';

import BulbIcon from '~/components/AiChat/SideBarDialog/icons/BulbIcon';
import ComplexityIcon from '~/components/AiChat/SideBarDialog/icons/ComplexityIcon';
import DataExtractionIcon from '~/components/AiChat/SideBarDialog/icons/DataExtractionIcon';
import DataPointIcon from '~/components/AiChat/SideBarDialog/icons/DataPointIcon';
import FinancialIcon from '~/components/AiChat/SideBarDialog/icons/FinancialIcon';
import WarningIcon from '~/components/AiChat/SideBarDialog/icons/WarningIcon';
import { CheckIcon } from '~/components/icons';

export const INSIGHTS_ICONS: Record<string, ReactElement> = {
  data_extraction: <DataExtractionIcon height={24} width={24} className="fill-pink-600 stroke-white" />,
  data_point: <DataPointIcon height={24} width={24} className="fill-pink-600 stroke-pink-600" />,
  warning: <WarningIcon height={24} width={24} className="fill-pink-600 stroke-white" />,
  general: <BulbIcon height={30} width={30} className="fill-white stroke-pink-600" />,
  financial: <FinancialIcon height={26} width={26} className="fill-pink-600 stroke-white" />,
  complexity: <ComplexityIcon height={30} width={30} className="fill-pink-600 stroke-pink-600" />,
  checked: (
    <CheckIcon
      height={24}
      width={24}
      className="rounded-full border-1 border-solid border-green-700 fill-green-700 px-4 pb-2"
    />
  ),
};

export const replaceDatesWithLocaleString = (inputString: string): string => {
  if (!inputString) return inputString;
  // Regular expression to match dates in the format "YYYY-MM-DDTHH:MM:SS" with optional milliseconds and microseconds
  const dateRegex = /\b(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{3,6})?)\b/g;

  const replacer = (match: string) => {
    const date = new Date(match);
    date.setMilliseconds(0);
    const options = {
      hour: 'numeric',
      minute: 'numeric',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return date.toLocaleString('en-US', options);
  };

  // Replace all occurrences of the date string with their locale string
  return inputString.replace(dateRegex, replacer);
};
