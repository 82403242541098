import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import requiredIf from 'react-required-if';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import type { DialogProps } from '@material-ui/core/Dialog/Dialog';
import type { Variant } from '@material-ui/core/styles/createTypography';

import { useStyles } from '~/assets/styles';
import Typography from '~/components/core/Atomic/Typography';
import DashIcon from '~/components/icons/DashIcon';
import { reportErrorInProductionOrThrow, trackModal } from '~/Utils';

import cn from '../Utils/cn';

import FsIconButton from './core/FsWrappers/FsIconButton/FsIconButton';
import { CloseIcon } from './icons';
import StablePopover from './StablePopover';

export interface CardDialogProps extends Omit<Partial<DialogProps>, 'title'> {
  children: React.ReactNode;
  open?: boolean;
  noCardTitle?: boolean;
  hideTitleContainer?: boolean;
  isPopover?: boolean;
  isDialog?: boolean;
  onClose?: () => void;
  preventClose?: boolean;
  title?: string | React.ReactNode;
  subheader?: React.ReactNode;
  trackAlt?: string;
  titleVariant?: Variant;
  dontTrack?: boolean;
  action?: React.ReactNode;
  closeOnBackdropClick?: boolean;
  outlinedCard?: boolean;
  passThrough?: boolean;
  width?: string;
  height?: string;
  containerClassName?: string;
  containerStyle?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  headerStyle?: React.CSSProperties;
  onEntered?: () => void;
  footerActions?: React.ReactNode;
  contentRef?: React.RefObject<HTMLDivElement>;
  onMinimized?: () => void;
  disableMinimized?: boolean;
  footerClassName?: string;
}

function CardDialog(props: CardDialogProps): JSX.Element {
  const {
    children,
    open,
    noCardTitle,
    hideTitleContainer = false,
    isDialog,
    isPopover,
    title,
    trackAlt,
    dontTrack,
    action,
    onClose,
    onMinimized,
    preventClose,
    disableMinimized,
    width,
    height,
    containerClassName,
    containerStyle,
    contentStyle,
    headerStyle = {},
    onEntered,
    closeOnBackdropClick,
    titleVariant,
    subheader,
    outlinedCard,
    passThrough,
    footerActions,
    contentRef,
    footerClassName,
    ...restProps
  } = props;

  const classes = useStyles();
  const location = useLocation();

  if (passThrough) {
    return <>{children}</>;
  }

  const actionWithClose = (
    <>
      {action}
      {onClose && (
        <FsIconButton
          onClick={onClose}
          disabled={preventClose}
          icon={CloseIcon}
          ignorePermissions={true}
          aria-label="Close dialog"
        />
      )}
    </>
  );

  const MinimizedButton = (
    <FsIconButton
      className="text-slate-900"
      onClick={onMinimized}
      disabled={disableMinimized}
      icon={DashIcon}
      ignorePermissions={true}
    />
  );

  const closeOnEscOnly = onClose
    ? undefined
    : (e: React.SyntheticEvent, reason: string) => {
        // close only on 'escapeKeyDown' not on 'backdropClick'
        if (reason === 'escapeKeyDown' && onClose) {
          return (onClose as () => void)();
        }
      };

  function onEnteredCombined() {
    if (onEntered) {
      onEntered();
    }

    if ((isDialog || isPopover) && !dontTrack) {
      trackModal(location, trackAlt || title);
    }
  }

  if (isDialog) {
    return (
      <Dialog
        open={open !== undefined ? open : true}
        onClose={onClose && !preventClose ? (closeOnBackdropClick ? onClose : closeOnEscOnly) : undefined}
        onEntered={onEnteredCombined}
        {...restProps}
      >
        {!hideTitleContainer ? (
          <DialogTitle disableTypography className={classes.containerTitle}>
            <div className="flex w-full items-center justify-between">
              <div className="w-full px-24 pb-12 pt-24" style={{ ...headerStyle }}>
                <Typography display="block" variant={titleVariant || 'h6'} style={{ fontSize: '18px' }}>
                  {title}
                </Typography>
              </div>
              <div style={{ position: 'relative', top: '5px', padding: '10px 18px' }}>
                <div className={classes.dialogTitleAction} style={{ display: 'flex', alignItems: 'center' }}>
                  {onMinimized ? MinimizedButton : null}
                  {actionWithClose}
                </div>
              </div>
            </div>
            <div style={{ paddingLeft: '24px' }}>{subheader}</div>
          </DialogTitle>
        ) : null}
        <DialogContent ref={contentRef} className={cn(classes.containerContent, containerClassName)}>
          {children}
        </DialogContent>
        {footerActions && <DialogActions className={footerClassName}>{footerActions}</DialogActions>}
      </Dialog>
    );
  }

  if (outlinedCard && action) {
    reportErrorInProductionOrThrow('outlinedCard should not have actions');
  }

  const cardComponent = (
    <Card style={{ width, height, ...containerStyle }}>
      {outlinedCard ? (
        <fieldset className={classes.outlinedCardFieldset}>
          {!noCardTitle && (
            <>
              <legend className={classes.outlinedCardLegend}>
                <Typography display="block" variant={titleVariant || 'subtitle1'}>
                  {title}
                </Typography>
              </legend>
              {subheader}
            </>
          )}
          <CardContent style={{ padding: '8px 8px 8px 8px', ...contentStyle }} className={containerClassName}>
            {children}
          </CardContent>
        </fieldset>
      ) : (
        <>
          {!noCardTitle && (
            <CardHeader
              classes={!subheader ? { action: classes.cardHeaderAction } : undefined}
              style={{ padding: '12px 12px 6px 16px', color: '#24327d', alignItems: 'flex-end', ...headerStyle }}
              title={title}
              titleTypographyProps={{ variant: titleVariant || 'h6' }}
              action={actionWithClose}
              subheader={subheader}
            />
          )}
          <CardContent style={{ padding: '6px 16px 16px 16px', ...contentStyle }} className={containerClassName}>
            {children}
          </CardContent>
        </>
      )}
    </Card>
  );

  if (isPopover) {
    return (
      <StablePopover
        open={!!open}
        onClose={onClose as () => void}
        onEntered={onEnteredCombined}
        closeOnBackdropClick={closeOnBackdropClick}
        {...restProps}
      >
        {cardComponent}
      </StablePopover>
    );
  }

  return cardComponent;
}

CardDialog.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool, // TODO: one day make it mandatory
  noCardTitle: PropTypes.bool,
  hideTitleContainer: PropTypes.bool,
  isPopover: PropTypes.bool,
  isDialog: PropTypes.bool,
  onClose: PropTypes.func,
  preventClose: PropTypes.bool,
  title: requiredIf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    (props: CardDialogProps) => !props.noCardTitle && !props.trackAlt
  ),
  subheader: PropTypes.node,
  trackAlt: PropTypes.string,
  titleVariant: PropTypes.string,
  dontTrack: PropTypes.bool,
  action: PropTypes.node,
  closeOnBackdropClick: PropTypes.bool,
  outlinedCard: PropTypes.bool,
  passThrough: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  containerClassName: PropTypes.string,
  containerStyle: PropTypes.object,
  contentStyle: PropTypes.object,
  headerStyle: PropTypes.object,
  onEntered: PropTypes.func,
  footerActions: PropTypes.node,
};

export default CardDialog;
