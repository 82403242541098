import type { ReactElement } from 'react';
import React from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import { isNil } from 'lodash';

import MenuItem from '~/components/core/Atomic/MenuItem';
import { stringCmp } from '~/Utils';

import Checkbox from '../../../Atomic/Checkboxes/Checkbox';
import OptionChips from '../../../OptionChips';
import type { TextFieldProps } from '../TextField';
import TextField from '../TextField';

// A for the all string option, E for the empty string option, T for the dropdown options
type MultiSelectValueType<T extends string, A = never, E = never> = [A] extends [never]
  ? [E] extends [never]
    ? T
    : T | E
  : [E] extends [never]
  ? T | A
  : T | A | E;

export interface MultiSelectFieldProps<T extends string, A = never, E = never>
  extends Omit<TextFieldProps<T>, 'onChange' | 'value'> {
  renderValue: (value: MultiSelectValueType<T, A, E>[]) => React.ReactNode;
  renderOption?: (option: MultiSelectValueType<T, A, E>) => React.ReactNode;
  options: T[];
  sortAlphabetic?: boolean;
  withOptionChips?: boolean;
  disabledOptions?: string[];
  addAllOption?: A extends never ? false : boolean;
  allOptionValue?: A;
  emptyChoiceValue?: E;
  onChange: (newValues: MultiSelectValueType<T, A, E>[]) => void;
  value?: MultiSelectValueType<T, A, E>[];
}

const MultiSelectField = <T extends string, A = never, E = never>({
  id,
  value,
  disabled,
  renderValue,
  renderOption,
  options,
  sortAlphabetic,
  addAllOption,
  disabledOptions,
  withOptionChips,
  allOptionValue,
  onChange,
  emptyChoiceValue,
  ...rest
}: MultiSelectFieldProps<T, A, E>): ReactElement => {
  if (sortAlphabetic) {
    options.sort((option1, option2) => {
      if (renderOption) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return stringCmp(renderOption(option1), renderOption(option2));
      }
      if (typeof option1 === 'string' && typeof option2 === 'string') {
        return stringCmp(option1, option2);
      }
      return 0;
    });
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const optionsWithPossibleAll = addAllOption ? [allOptionValue].concat(options) : options;

  const isOptionChecked = (option: MultiSelectValueType<T, A, E>) => {
    if (addAllOption && allOptionValue === option && value?.length === 0) {
      return true;
    }
    return (value?.indexOf(option) ?? -1) > -1;
  };

  const innerOnChange = (newValues: MultiSelectValueType<T, A, E>[]) => {
    const currentValue = value;
    const nextValue = newValues;

    if (allOptionValue) {
      if (nextValue.includes(allOptionValue as MultiSelectValueType<T, A, E>)) {
        // allOptionValue was selected and user now selected something else - unselect allOptionValue
        if (currentValue?.includes(allOptionValue as MultiSelectValueType<T, A, E>)) {
          return onChange(nextValue.filter((i) => i !== allOptionValue));
        } else {
          // user selected allOptionValue - unselect everything else
          return onChange([allOptionValue as MultiSelectValueType<T, A, E>]);
        }
      }
    }

    if (emptyChoiceValue && nextValue.includes(emptyChoiceValue as MultiSelectValueType<T, A, E>)) {
      // emptyChoiceValue was selected and user now selected something else - unselect emptyChoiceValue
      if (currentValue?.includes(emptyChoiceValue as MultiSelectValueType<T, A, E>)) {
        return onChange(nextValue.filter((i) => i !== emptyChoiceValue));
      } else {
        // user selected emptyChoiceValue - unselect everything else
        return onChange([emptyChoiceValue as MultiSelectValueType<T, A, E>]);
      }
    }

    return onChange(newValues);
  };

  const removeSingleValueFromSelectedOptions = (
    id: string | undefined,
    values: MultiSelectValueType<T, A, E>[] | undefined,
    removedValue: MultiSelectValueType<T, A, E>
  ) => {
    const indexValue = values?.indexOf(removedValue);
    !isNil(indexValue) && values?.splice(indexValue, 1);
    innerOnChange([...(values ?? [])]);
  };

  return (
    <div>
      <TextField
        name={id}
        value={value}
        disabled={disabled}
        select
        onChange={innerOnChange}
        SelectProps={{
          multiple: true,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          renderValue:
            renderValue ??
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ((selected: T[]) => (renderOption ? selected.map(renderOption).join(', ') : selected.join(', '))),
        }}
        id={id}
        {...rest}
      >
        {optionsWithPossibleAll.map((option) => {
          if (isNil(option)) return <></>;
          const isChecked = isOptionChecked(option as MultiSelectValueType<T, A, E>);
          return (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <MenuItem key={option as string} value={option} disabled={disabledOptions?.includes(option)}>
              <Checkbox checked={isChecked} style={{ color: isChecked ? '#1A9C9E' : '#707070' }} />
              <ListItemText
                primary={
                  renderOption && (!addAllOption || (addAllOption && option !== 'All'))
                    ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      renderOption(option)
                    : option
                }
              />
            </MenuItem>
          );
        })}
      </TextField>
      {withOptionChips && (
        <OptionChips
          disabled={disabled}
          onChange={(removedValue) => removeSingleValueFromSelectedOptions(id, value, removedValue)}
          values={value?.map((v) => [
            v,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            renderOption && (!addAllOption || (addAllOption && v !== allOptionValue)) ? renderOption(v) : v,
          ])}
          disabledChips={disabledOptions}
        />
      )}
    </div>
  );
};

export default MultiSelectField;
