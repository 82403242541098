import { each } from 'lodash';

export const SUPPORTED_COUNTRIES = ['US', 'GB', 'AU'];

export interface ParsedAddressComponentsType {
  subpremise?: string;
  street_number?: string;
  route?: string;
  locality?: string;
  postal_town?: string;
  administrative_area_level_1?: string;
  administrative_area_level_2?: string;
  postal_code?: string;
  postal_code_suffix?: string;
  country?: string;
}

export const parseAddressComponents = (
  addressComponents: google.maps.GeocoderAddressComponent[]
): ParsedAddressComponentsType => {
  const componentForm = {
    subpremise: 'short_name',
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    postal_town: 'long_name',
    administrative_area_level_1: 'short_name',
    administrative_area_level_2: 'short_name',
    postal_code: 'short_name',
    postal_code_suffix: 'short_name',
    country: 'short_name',
  };

  const parsedAddress: ParsedAddressComponentsType = {};

  each(addressComponents, (component) => {
    const addressType = component.types[0];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (componentForm[addressType]) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      parsedAddress[addressType] = component[componentForm[addressType]];
    }
  });

  return parsedAddress;
};
