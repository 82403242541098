import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const TranslatedFileIcon = ({ iconColor = '#202020', ...props }) => (
  <SvgIcon viewBox="0 0 24 24" disableStrokeColor {...props} style={{ width: '24px', height: '24px' }}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.793 1.793A1 1 0 0 1 3.5 1.5h6a.5.5 0 0 1 .354.146l3.5 3.5a.5.5 0 0 1 .146.354V8a.5.5 0 0 1-1 0V5.707L9.293 2.5H3.5V8a.5.5 0 0 1-1 0V2.5a1 1 0 0 1 .293-.707Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 1.5a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1H9.5a.5.5 0 0 1-.5-.5V2a.5.5 0 0 1 .5-.5Z"
      fill={iconColor}
    />
    <path
      d="m11.585 12.582-1.75-3.5a.375.375 0 0 0-.67 0l-.642 1.281a2.63 2.63 0 0 1-1.21-.419 3.359 3.359 0 0 0 .791-1.819h.646a.375.375 0 1 0 0-.75H7.125V7a.375.375 0 0 0-.75 0v.375H4.75a.375.375 0 0 0 0 .75h2.598c-.07.489-.278.948-.598 1.323a2.625 2.625 0 0 1-.388-.604.375.375 0 1 0-.681.312c.131.286.302.551.506.789a2.61 2.61 0 0 1-1.437.43.375.375 0 1 0 0 .75c.72 0 1.421-.23 2-.658.42.308.905.516 1.418.607l-.754 1.508a.375.375 0 1 0 .671.335l.397-.792h2.036l.396.793a.374.374 0 1 0 .671-.336Zm-2.728-1.207.643-1.287.643 1.287H8.857Z"
      fill={iconColor}
    />
    <defs>
      <linearGradient id="a" x1="12.813" y1="-.25" x2="7.667" y2="12.349" gradientUnits="userSpaceOnUse">
        <stop stopColor="#2E4E6C" />
        <stop offset=".543" stopColor="#3B98B7" />
        <stop offset=".948" stopColor="#8B90E1" />
      </linearGradient>
      <linearGradient id="b" x1="12.813" y1="-.25" x2="7.667" y2="12.349" gradientUnits="userSpaceOnUse">
        <stop stopColor="#2E4E6C" />
        <stop offset=".543" stopColor="#3B98B7" />
        <stop offset=".948" stopColor="#8B90E1" />
      </linearGradient>
      <linearGradient id="c" x1="11.172" y1="4.998" x2="5.26" y2="15.26" gradientUnits="userSpaceOnUse">
        <stop stopColor="#2E4E6C" />
        <stop offset=".543" stopColor="#3B98B7" />
        <stop offset=".948" stopColor="#8B90E1" />
      </linearGradient>
    </defs>
  </SvgIcon>
);

TranslatedFileIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default TranslatedFileIcon;
