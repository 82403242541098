import React from 'react';

import { FileIcon } from '../icons';
import TranslatedFileIcon from '../icons/TranslatedFileIcon';
import InlineIconButton from '../InlineIconButton';

export interface ViewDocumentButtonProps {
  document: {
    id: number;
    claim_id: number;
    is_uploading: boolean;
    is_translated: boolean;
  };
}

const ViewDocumentButton: React.FC<ViewDocumentButtonProps> = ({ document }) => {
  const isDisabled = document.is_uploading;

  return (
    <>
      <a
        href={isDisabled ? '#' : `/api/v1/claims/${document.claim_id}/documents/${document.id}`}
        target="_blank"
        rel="noreferrer"
      >
        <InlineIconButton
          tooltipTitle={
            isDisabled
              ? 'Document is stll uploading...'
              : document.is_translated
              ? 'View original document'
              : 'View document'
          }
          icon={FileIcon}
          ignorePermissions
          disabled={isDisabled}
        />
      </a>
      {document.is_translated && (
        <a
          href={isDisabled ? '#' : `/api/v1/claims/${document.claim_id}/documents/${document.id}/translation`}
          target="_blank"
          rel="noreferrer"
        >
          <InlineIconButton
            tooltipTitle={isDisabled ? 'Document is still uploading...' : 'View translated document'}
            icon={TranslatedFileIcon}
            ignorePermissions
            disabled={isDisabled}
          />
        </a>
      )}
    </>
  );
};

export default ViewDocumentButton;
