import type { ReactElement } from 'react';
import React, { useState } from 'react';
import axios from 'axios';

import { getClaimNotificationIcon } from '~/components/ClaimNotificationsCard/ClaimNotificationCardUtils';
import type { NotificationTypesUnion } from '~/components/ClaimNotificationsCard/types';
import Divider from '~/components/core/Atomic/Divider/Divider';
import LabeledValue from '~/components/core/Molecules/LabledValue';
import NotificationAssignee from '~/components/Notifications/Assignee';
import CategoryEditableChip from '~/components/Notifications/CategoryEditableChip';
import DismissButton from '~/components/Notifications/DismissButton';
import MarkAsUnreadButton from '~/components/Notifications/MarkAsUnreadButton';
import PriorityEditableChip from '~/components/Notifications/PriorityEditableChip';
import { changeClaimNotificationState, getClaimNotificationDateStr } from '~/components/Notifications/utils';
import { reportAxiosError } from '~/Utils';

import Caption from '../core/TextComponents/Caption';

export interface GenericNotificationHeaderProps {
  notification: NotificationTypesUnion;
  onUpdateNotification: () => Promise<void>;
  onClose: () => void;
}
const GenericNotificationHeader: React.FC<GenericNotificationHeaderProps> = ({
  notification,
  onUpdateNotification,
  onClose,
}) => {
  const [isUpdating, setIsUpdating] = useState(false);

  const handleMarkAsUnread = async () => {
    try {
      setIsUpdating(true);
      await axios.post(`/api/v1/claims/notifications/${notification.id}/mark_as_unread`);
      await onUpdateNotification();
      onClose();
    } catch (error) {
      reportAxiosError(error);
    }
    setIsUpdating(false);
  };

  return (
    <div>
      <div className="mb-16 border-1 border-solid border-slate-600 bg-slate-200 pb-12 shadow-sm">
        <div className="m-12 p-12">
          <div className="grid grid-cols-5 gap-12">
            <ComponentWithLabel label="Type" ariaLabel="notification-icon">
              {getClaimNotificationIcon(notification, undefined, 20)}
            </ComponentWithLabel>
            <ComponentWithLabel label="Priority">
              <PriorityEditableChip
                notification={notification}
                setIsUpdating={setIsUpdating}
                onUpdate={onUpdateNotification}
                disabled={isUpdating}
              />
            </ComponentWithLabel>
            {/*TODO: ellahellah will be editable in NGTPA-17375*/}
            <LabeledValue value={getClaimNotificationDateStr(notification)} label="Due Date" />
            <ComponentWithLabel label="Assignee">
              <NotificationAssignee notification={notification} />
            </ComponentWithLabel>
            <ComponentWithLabel label="Category">
              <CategoryEditableChip
                claimNotification={notification}
                disabled={isUpdating}
                setIsUpdating={setIsUpdating}
                onUpdate={onUpdateNotification}
              />
            </ComponentWithLabel>
          </div>
        </div>
        <Divider className="mb-16 mt-16 w-full" />

        <div className="m-8 flex items-center justify-between">
          <DismissButton
            notification={notification}
            onClick={async () => {
              await changeClaimNotificationState(
                notification,
                () => setIsUpdating(true),
                () => setIsUpdating(false),
                onUpdateNotification
              );
              onClose();
            }}
            tooltipTextDismiss="Dismiss and close"
            tooltipTextUndismiss="Undismiss and close"
            disabled={isUpdating}
            textDismiss="DISMISS AND CLOSE"
            textUndismiss="UNDISMISS AND CLOSE"
          />
          <MarkAsUnreadButton onClick={handleMarkAsUnread} disabled={isUpdating} />
        </div>
      </div>
    </div>
  );
};

const ComponentWithLabel: React.FC<{ label: string; children: ReactElement; ariaLabel?: string }> = ({
  label,
  children,
  ariaLabel,
}) => (
  <div aria-label={ariaLabel}>
    <Caption variant={Caption.VARIANTS.LABEL} className="mb-4">
      {label}
    </Caption>
    {children}
  </div>
);

export default GenericNotificationHeader;
