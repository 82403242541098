import axios from 'axios';

import type { ErrorJson, ErrorOccurrence } from './types';

const createErrorMonitor = (timeWindowMs = 60 * 1000, threshold = 10) => {
  const errorCounts: Record<string, ErrorOccurrence[]> = {};

  const getErrorKey = (error: Error): string => `other_${error.message}_${error.name}`;

  const trackError = (error: Error): { shouldAlert: boolean; occurrences: ErrorOccurrence[]; errorKey: string } => {
    const errorKey = getErrorKey(error);
    const currentTime = Date.now();
    const occurrence: ErrorOccurrence = { timestamp: currentTime };

    if (!errorCounts[errorKey]) {
      errorCounts[errorKey] = [];
    }

    errorCounts[errorKey].push(occurrence);

    const timeThreshold = currentTime - timeWindowMs;
    errorCounts[errorKey] = errorCounts[errorKey].filter(({ timestamp }) => timestamp >= timeThreshold);

    return {
      shouldAlert: errorCounts[errorKey].length >= threshold,
      occurrences: errorCounts[errorKey],
      errorKey,
    };
  };

  const clear = (errorKey: string) => {
    errorCounts[errorKey] = [];
  };

  return {
    trackError,
    getErrorKey,
    clear,
  };
};

const sendCriticalAlert = async (errorJson: ErrorJson, occurrences: ErrorOccurrence[]): Promise<void> => {
  const alertData = {
    message: `Error: ${errorJson.type} - ${JSON.stringify(errorJson.data)}. occurrences: ${occurrences.length}`,
  };

  try {
    await axios.post('/api/v1/critical-errors', alertData);
  } catch (alertError) {
    // eslint-disable-next-line no-console
    console.error('Failed to send critical alert:', alertError);
  }
};

type ReportAxiosErrorFn = (error: Error | unknown, openDialogError?: boolean) => Promise<ErrorJson | undefined>;

export const wrapReportError = (reportErrorFunction: ReportAxiosErrorFn): ReportAxiosErrorFn => {
  const errorMonitor = createErrorMonitor();

  return async (error: unknown, openDialogError = true): Promise<ErrorJson | undefined> => {
    const errorObject = error instanceof Error ? error : new Error(String(error));

    const errorJson = await reportErrorFunction(errorObject, openDialogError);

    const { shouldAlert, occurrences, errorKey } = errorMonitor.trackError(errorObject);
    if (errorJson && shouldAlert) {
      await sendCriticalAlert(errorJson, occurrences);
      errorMonitor.clear(errorKey);
    }
    return errorJson;
  };
};
