import React from 'react';
import type { AxiosError } from 'axios';

import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';

import { LockIllustration } from '../../illustrations';

import styles from './PermissionError.module.scss';

interface DialogConfig {
  title: string;
  contentComponent: React.ReactNode;
  showOkButton: boolean;
}

export const getPermissionDeniedDialogConfig = (error: AxiosError): DialogConfig => ({
  title: '',
  contentComponent: <PermissionError httpError={error} />,
  showOkButton: true,
});

const DEFAULT_MESSAGE =
  "But it looks like you don't have permission to access this resource. Please refer to your system administrator";

interface PermissionErrorProps {
  httpError?: AxiosError<{ message?: { permission_id?: string } }>;
  action?: string;
  verb?: string;
  message?: string;
}

const PermissionError: React.FC<PermissionErrorProps> = ({ httpError, action, verb, message }) => {
  const httpPermissionId = httpError?.response?.data?.message?.permission_id;
  const permissionId = action && verb ? `${action}:${verb}` : null;

  const weAreSorry = "We're Sorry";

  return (
    <div className={styles.contentWrapper}>
      <Grid container direction="column">
        <div className={styles.illustrationContainer}>
          <LockIllustration />
        </div>
        <div className={styles.textContainer}>
          <Typography className={styles.title} display="block">
            {weAreSorry}
          </Typography>
          <span className={styles.sorrySpan}>{message ?? DEFAULT_MESSAGE}</span>
          {(httpPermissionId ?? permissionId) && (
            <span className={styles.permissionSpan}>Permission ID - {httpPermissionId ?? permissionId}</span>
          )}
        </div>
      </Grid>
    </div>
  );
};

export default PermissionError;
