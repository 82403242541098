import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import * as Yup from 'yup';

import Grid from '~/components/core/Atomic/Grid/Grid';
import useSafeFormikContext from '~/components/hooks/useSafeFormikContext';

import { useIncidentConfiguration } from '../../../../../hooks/useIncidentConfiguration';
import { useLob } from '../../../../../hooks/useLob';
import ConfiguredFields, {
  getConfiguredFieldsEmptyFormikInitialValues,
  getConfiguredFieldsValidations,
  getInitialSectionConfig,
  getPredefinedFieldsEmptyFormikInitialValues,
  PreDefinedField,
  preparePreDefinedFields,
} from '../../../../../IncidentConfiguration/ConfiguredFields';
import { getAdditionalDataValidations } from '../../../../../IncidentConfiguration/CustomFieldsContext';
import { usePolicy } from '../../../../../PolicyContainer';

const SUPPLEMENTARY_BASE_FIELDS = [
  'did_anyone_take_photos_or_video',
  'photographer_contact_id',
  'is_other_policy_covering',
  'is_notification_only',
  'was_cctv_footage',
  'can_take_photos_of_damage',
  'other_policy_description',
];

const SUPPLEMENTARY_INFORMATION_LOB_SPECIFIC_FIELDS = {
  auto_claim: [
    'was_replacement_car_rented',
    'replacement_car_rental_date',
    'are_convictions_or_claims_in_the_last_5_years',
    'convictions_or_claim_description',
    'is_policyholder_occupation_right',
    'policyholder_current_occupation',
    'does_policyholder_second_occupation_exist',
    'policyholder_second_occupation',
  ],
};

const LOB_ADDITIONAL_PATH = {
  auto_claim: 'auto_incident_additional',
};

const SUPPLEMENTARY_INFORMATION_PATH = 'incident_details.supplementary_information'; // Save those values together in the FE
const SUPPLEMENTARY_INFORMATION_CONFIG_PATH = 'incident_details.pre_defined_fields.supplementary_information';
const BASE_CONFIG_PATH = 'incident_details.pre_defined_fields';

// this function is the reflection of fnol_api_to_fe_converter.py::_extract_supplementary_information_values_from_incident_details in the backend, please change in tandem
const mapSupplementaryInformationToIncident = (values) => {
  const {
    did_anyone_take_photos_or_video,
    photographer_contact_id,
    is_other_policy_covering,
    is_notification_only,
    was_cctv_footage,
    can_take_photos_of_damage,
    other_policy_description,
  } = get(values, SUPPLEMENTARY_INFORMATION_PATH, {});

  return {
    did_anyone_take_photos_or_video,
    photographer_contact_id,
    is_other_policy_covering,
    is_notification_only,
    was_cctv_footage,
    can_take_photos_of_damage,
    other_policy_description,
  };
};

// TODO: NGTPA-15667 - should be in the config + migrate existing configs - not in the form itself
const getIsConfiguredFieldsActive = (incidentConfiguration) =>
  get(incidentConfiguration, 'incident_details.configured_fields', [])
    ?.filter((field) => field?.section === 'supplementary_info')
    ?.some((field) => field?.active || field?.mandatory);

const getIsSupplementaryInformationSectionActive = (incidentConfiguration) => {
  const isSupplementaryInformationSectionActive = get(
    incidentConfiguration,
    SUPPLEMENTARY_INFORMATION_CONFIG_PATH,
    {}
  )?.active;

  const isConfiguredFieldsActive = getIsConfiguredFieldsActive(incidentConfiguration);

  return isSupplementaryInformationSectionActive || isConfiguredFieldsActive;
};

const getSupplementaryInformationSectionConfig = (incidentConfiguration, isFieldSupportedBySubtype) =>
  getInitialSectionConfig(
    incidentConfiguration,
    SUPPLEMENTARY_INFORMATION_CONFIG_PATH,
    { active: true, mandatory: true },
    isFieldSupportedBySubtype
  );

const getSupplementaryInformationSectionTitle = (incidentConfiguration) =>
  get(getSupplementaryInformationSectionConfig(incidentConfiguration), 'desc', 'Supplementary Information');

const getSupplementaryInformationPredefinedFields = (incidentConfiguration, lob, additionalFieldOmitFn) => {
  const preDefinedFields = {};
  SUPPLEMENTARY_BASE_FIELDS.forEach((fieldId) => {
    preDefinedFields[fieldId] = get(incidentConfiguration, `${BASE_CONFIG_PATH}.${fieldId}`, {});
  });

  if (Object.keys(SUPPLEMENTARY_INFORMATION_LOB_SPECIFIC_FIELDS).includes(lob)) {
    SUPPLEMENTARY_INFORMATION_LOB_SPECIFIC_FIELDS[lob].forEach((fieldId) => {
      preDefinedFields[fieldId] = get(
        incidentConfiguration,
        `${BASE_CONFIG_PATH}.${LOB_ADDITIONAL_PATH[lob]}.${fieldId}`
      );
    });
  }

  return preparePreDefinedFields(preDefinedFields, additionalFieldOmitFn);
};

const getSupplementaryInformationInitialValues = (incidentConfiguration, lob, additionalFieldOmitFn) => ({
  ...getPredefinedFieldsEmptyFormikInitialValues(
    getSupplementaryInformationPredefinedFields(incidentConfiguration, lob, additionalFieldOmitFn)
  ),
  configured_fields_values: getConfiguredFieldsEmptyFormikInitialValues(
    get(incidentConfiguration, 'incident_details.configured_fields', []),
    'supplementary_info',
    additionalFieldOmitFn
  ),
});

const getSupplementaryInformationValidationSchema = (incidentConfiguration, lob, additionalFieldOmitFn) => ({
  ...getAdditionalDataValidations(
    getSupplementaryInformationPredefinedFields(incidentConfiguration, lob, additionalFieldOmitFn)
  ),
  configured_fields_values: Yup.object().shape({
    ...getConfiguredFieldsValidations(incidentConfiguration, 'supplementary_info', additionalFieldOmitFn),
  }),
});

const SupplementaryInformationFragment = ({ additionalFieldOmitFn }) => {
  const { lob } = useLob();
  const { policy } = usePolicy();
  const { incidentConfiguration } = useIncidentConfiguration();
  const preDefinedFields = getSupplementaryInformationPredefinedFields(
    incidentConfiguration,
    lob,
    additionalFieldOmitFn
  );
  const configurableFields = incidentConfiguration.incident_details.configured_fields.filter((field) =>
    additionalFieldOmitFn ? additionalFieldOmitFn(field) : true
  );
  const formikContext = useSafeFormikContext();

  return (
    <>
      <PreDefinedField id="did_anyone_take_photos_or_video" fields={preDefinedFields} inline />
      <PreDefinedField id="was_cctv_footage" fields={preDefinedFields} inline />
      <PreDefinedField id="can_take_photos_of_damage" fields={preDefinedFields} inline />
      <PreDefinedField id="photographer_contact_id" fields={preDefinedFields} inline />
      <PreDefinedField id="is_other_policy_covering" fields={preDefinedFields} inline />
      <PreDefinedField id="other_policy_description" fields={preDefinedFields} inline />
      <PreDefinedField id="is_notification_only" fields={preDefinedFields} inline gridXs={12} />
      <PreDefinedField id="was_replacement_car_rented" fields={preDefinedFields} inline gridXs={12} />
      <PreDefinedField id="replacement_car_rental_date" fields={preDefinedFields} inline disableFuture />
      <PreDefinedField id="are_convictions_or_claims_in_the_last_5_years" fields={preDefinedFields} inline />
      <PreDefinedField id="convictions_or_claim_description" fields={preDefinedFields} inline gridXs={12} />
      <PreDefinedField
        id="is_policyholder_occupation_right"
        fields={preDefinedFields}
        desc={`Is current occupation '${policy?.insured_contact?.occupation || 'Not Found'}'?`}
        inline
        gridXs={12}
      />
      <PreDefinedField id="policyholder_current_occupation" fields={preDefinedFields} inline gridXs={12} />
      <PreDefinedField id="does_policyholder_second_occupation_exist" fields={preDefinedFields} inline gridXs={12} />
      <PreDefinedField id="policyholder_second_occupation" fields={preDefinedFields} inline gridXs={12} />
      <Grid item xs={12}>
        <ConfiguredFields
          values={formikContext ? formikContext.values : {}}
          customFields={configurableFields.filter((field) => field.section === 'supplementary_info')}
          inline
        />
      </Grid>
    </>
  );
};

SupplementaryInformationFragment.propTypes = {
  additionalFieldOmitFn: PropTypes.func,
};

export {
  getIsSupplementaryInformationSectionActive,
  getSupplementaryInformationInitialValues,
  getSupplementaryInformationSectionConfig,
  getSupplementaryInformationSectionTitle,
  getSupplementaryInformationValidationSchema,
  mapSupplementaryInformationToIncident,
  SUPPLEMENTARY_INFORMATION_PATH,
  SupplementaryInformationFragment,
};
