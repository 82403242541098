import React, { useEffect, useState } from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import PropTypes from 'prop-types';
import axios from 'axios';
import { orderBy } from 'lodash';

import { useStyles } from '~/assets/styles';
import Button from '~/components/core/Atomic/Buttons/Button';
import Autocomplete from '~/components/core/Molecules/Fields/AutoComplete';
import SkeletonTable from '~/components/core/Skeletons/SkeletonTable';
import { COMMUNICATION_CHANNEL_DICT, CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isUserImpersonated } from '~/UserUtils';
import { formatSortOrder, isFeatureEnabled, isProductionEnv, reportErrorInProductionOrThrow } from '~/Utils';

import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import ContactSearchContainer from '../ContactSearch/ContactSearchContainer';
import ExposuresLabelFilter from '../exposures/ExposuresLabelsFilter';
import { getUserRelatedExposuresId } from '../exposures/ExposureUtils';
import { useCms } from '../hooks/useCms';
import useOrganization from '../OrganizationContext';
import useDataFetcher from '../useDataFetcher';

import CommunicationsTable from './CommunicationsTable';
import { getAllSearchableContactRoles } from './ContactUtils';
import ViewCommunicationCardContainer from './ViewCommunicationCardContainer';

const CommunicationsTab = ({ isTabActive = true }) => {
  const { claim, onClaimUpdate } = useClaim();
  const { user, userOrganization } = useCms();
  const communicationsPerPage = 10;

  const classes = useStyles();
  const { organizationContactRolesDict } = useOrganization();

  const [selectedContact, setSelectedContact] = useState('');
  const [selectedAdjuster, setSelectedAdjuster] = useState('');
  const [selectedCommunicationId, setSelectedCommunicationId] = useState(undefined);
  const [filteredExposuresIds, setFilteredExposuresIds] = useState(() => getUserRelatedExposuresId(user, claim));
  const [page, setPage] = useState(0);
  const [sortByColumn, setSortByColumn] = useState({ id: 'datetime', order: 'desc' });
  const [selectedCommunicationChannel, setSelectedCommunicationChannel] = useState('');
  const isNotification2Enabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.NOTIFICATIONS_2);

  const {
    isLoading,
    isError,
    data: {
      communications,
      count: totalCommunicationsCount,
      communications_adjusters: communicationAdjusters,
      available_channels,
      communications_read_status: communicationsReadStatusDict = {},
    } = {},
    reloadData,
  } = useDataFetcher(`/api/v1/claims/${claim.id}/communications`, {
    params: {
      page_number: page + 1,
      results_per_page: communicationsPerPage,
      sort_by_column: formatSortOrder(sortByColumn),
      exposures_filters: filteredExposuresIds,
      channel_type_filter: undefined,
      contact_id: selectedContact ? selectedContact.id : undefined,
      user_id: selectedAdjuster ? selectedAdjuster.id : undefined,
      communication_channel: selectedCommunicationChannel ? selectedCommunicationChannel : undefined,
      with_read_status: isNotification2Enabled ? true : undefined,
    },
  });

  useEffect(() => {
    reloadData && reloadData();
  }, [claim, reloadData]);

  const handleSelectCommunication = async (communicationId) => {
    setSelectedCommunicationId(communicationId);

    try {
      if (communicationsReadStatusDict?.[communicationId]) return;
      if (isProductionEnv() && isUserImpersonated(user)) return;

      await axios.post(`/api/v1/communications/${communicationId}/mark_as_read`);
      await reloadData();
    } catch (error) {
      reportErrorInProductionOrThrow(error);
    }
  };

  const displayLoading = isLoading && !communications;

  const resetPage = () => setPage(0);

  const unreadRowsIds = isNotification2Enabled
    ? communications?.filter((communication) => !communicationsReadStatusDict?.[communication.id]).map((n) => n.id) ||
      []
    : [];

  return isTabActive ? (
    <>
      <div className="flex h-full flex-col overflow-auto">
        <div className="grid grid-cols-2">
          <div>
            <div className={classes.cardDivRow}>
              <div className="flex flex-wrap items-end gap-x-12">
                <ContactSearchContainer
                  disallowNew
                  fixedSearchResults
                  onSelectContact={(contact) => {
                    setSelectedContact(contact);
                    resetPage();
                  }}
                  selectedContactId={selectedContact ? selectedContact.id : undefined}
                  selectedContactDisplayName={selectedContact ? selectedContact.full_name : undefined}
                  TextFieldProps={{
                    label: 'Filter by Contact',
                  }}
                  acceptedRoles={getAllSearchableContactRoles(organizationContactRolesDict).concat(['user'])}
                  disallowOutOfClaimContacts
                  disabled={isLoading}
                />
                <div>
                  <Autocomplete
                    id="adjuster"
                    label="Filter by Adjuster"
                    options={orderBy(communicationAdjusters, ['username'])}
                    getOptionLabel={(option) => option.username}
                    onChange={(_, newVal) => {
                      setSelectedAdjuster(newVal || '');
                      resetPage();
                    }}
                    value={selectedAdjuster || null}
                    className="w-[200px]"
                    textFieldProps={{ className: 'my-0' }}
                  />
                </div>
                <div>
                  <Autocomplete
                    id="channel"
                    label="Filter by Type"
                    options={available_channels}
                    getOptionLabel={(option) => COMMUNICATION_CHANNEL_DICT[option]}
                    onChange={(_, newVal) => {
                      resetPage();
                      setSelectedCommunicationChannel(newVal || '');
                    }}
                    value={selectedCommunicationChannel || null}
                    className="w-[200px]"
                    textFieldProps={{ className: 'my-0' }}
                  />
                </div>
                <Button
                  disabled={!selectedContact && !selectedAdjuster && !selectedCommunicationChannel}
                  onClick={() => {
                    setSelectedContact('');
                    setSelectedAdjuster('');
                    setSelectedCommunicationChannel('');
                    resetPage();
                  }}
                >
                  Clear
                </Button>
              </div>
            </div>
          </div>
          <div className="flex flex-row flex-wrap items-center justify-start">
            <ExposuresLabelFilter filterList={filteredExposuresIds} onUpdateFiltered={setFilteredExposuresIds} />
          </div>
        </div>
        <PanelGroup direction="horizontal" id="group-claim-communications" autoSaveId>
          <Panel id="table-display" defaultSize={selectedCommunicationId ? 60 : 100} style={{ overflow: 'auto' }}>
            <CardDialog noCardTitle>
              {displayLoading && <SkeletonTable rowsCount={15} columnsCount={5} isError={isError} />}
              {!displayLoading && (
                <CommunicationsTable
                  communications={communications}
                  selectedCommunicationId={selectedCommunicationId}
                  onSelectCommunication={handleSelectCommunication}
                  unreadRowsIds={unreadRowsIds}
                  paginationProps={{
                    page,
                    rowsPerPage: communicationsPerPage,
                    onChangePage: (_, newPage) => {
                      setPage(newPage);
                    },
                    disabled: isLoading,
                    count: totalCommunicationsCount || 0,
                    rowsPerPageOptions: [communicationsPerPage],
                  }}
                  onSortByColumn={(_, sortByColumn) => {
                    setSortByColumn(sortByColumn);
                    resetPage();
                  }}
                  sortByColumn={sortByColumn}
                  removeEmailDomainColumn
                />
              )}
            </CardDialog>
          </Panel>
          {selectedCommunicationId && (
            <>
              <PanelResizeHandle id="resize-handle" className="mx-12 w-2 rounded-lg bg-slate-500" />

              <Panel id="comm-display" className="h-full" style={{ overflow: 'auto' }}>
                <CardDialog
                  headerStyle={{ padding: '0' }}
                  contentStyle={{
                    padding: '0',
                  }}
                >
                  <ViewCommunicationCardContainer
                    key={selectedCommunicationId}
                    communicationId={selectedCommunicationId}
                    onUpdate={onClaimUpdate}
                  />
                </CardDialog>
              </Panel>
            </>
          )}
        </PanelGroup>
      </div>
    </>
  ) : null;
};

CommunicationsTab.propTypes = {
  isTabActive: PropTypes.bool,
};

export default CommunicationsTab;
