import React from 'react';

import { RoundedPanel } from '~/components/AiChat/SideBarDialog/components/RoundedPanel/RoundedPanel';
import { replaceDatesWithLocaleString } from '~/components/AiChat/SideBarDialog/utils';
import type { ActionTaskData, AiAction } from '~/components/AiChat/types';
import { MarkdownSection } from '~/components/core/MarkdownSection';

interface ActionsPanelProps {
  action: AiAction;
}

export const GeneralActionInner: React.FC<ActionsPanelProps> = ({ action }) => {
  const { task_text: taskText, explanation } = action.task_data as ActionTaskData;

  return (
    <RoundedPanel variant="clear">
      <MarkdownSection markdown={replaceDatesWithLocaleString(taskText)} />
      <MarkdownSection markdown={replaceDatesWithLocaleString(explanation)} />
    </RoundedPanel>
  );
};
