import type { AxiosError } from 'axios';

interface ErrorResponse {
  data?: {
    message?: string;
  };
  status?: number;
}

interface ExtractedError {
  title: string;
  message: string;
  reportError: boolean;
}

const isClaimNotFoundError = (error: AxiosError<{ message?: string }>): false | boolean | undefined =>
  error?.response?.status === 404 && error?.response?.data?.message?.startsWith('Claim not found');

export const extractError = (error: AxiosError<{ message?: string }>): ExtractedError => {
  const response = error.response as ErrorResponse;
  const message = response?.data?.message;

  if (
    response &&
    (message === 'User is not authorized to perform this operation' ||
      message?.match(/Role type .* not authorized for function .*/))
  ) {
    return {
      title: 'Authorization Error',
      message: 'User is not authorized to perform this operation.',
      reportError: false,
    };
  }

  // Notify User Specific Errors
  if (message?.startsWith('Notify User:')) {
    return {
      title: 'Error',
      message: message.replace('Notify User:', '').trim(),
      reportError: false,
    };
  }

  // Claim Not Found
  if (isClaimNotFoundError(error)) {
    return {
      title: 'Claim Not Found',
      message: 'Please verify the url',
      reportError: false,
    };
  }

  // Generic Unexpected Error
  return {
    title: 'Unexpected Error',
    message: 'Unfortunately an unexpected error has occurred. We are working to solve it.',
    reportError: true,
  };
};
