import GENERIC_TEMPLATES_TYPES from '~/server_shared/generated-types/GENERIC_TEMPLATES_TYPES';

export const TEMPLATES_PER_PAGE = 20;

export const TEMPLATES_TYPES = {
  ...GENERIC_TEMPLATES_TYPES,
  document: { display_name: 'Document', template_title_required: true },
};
export const DEFAULT_TEMPLATES_PAGINATION_PROPS = {
  page: 0,
  limit: TEMPLATES_PER_PAGE,
  sort_by: 'template_name',
  order_by: 'asc',
};

export const ADVANCED_TEMPLATES_SEARCH_KEYS = {
  COVERAGE_KEYS: 'coverage_keys',
  STATES: 'states',
  SUB_ORGANIZATION_IDS: 'sub_organization_ids',
  LOBS: 'lobs',
  COUNTRY: 'country',
  TEMPLATE_NAME: 'template_name',
};

export const TEMPLATE_BUILDER_FORM_KEYS = {
  IS_ALL_SUBORGS: 'is_all_suborgs',
  SUB_ORGANIZATION_IDS: 'sub_organization_ids',
  IS_ALL_LOBS: 'is_all_lobs',
  LOBS: 'lobs',
  COVERAGE_KEYS: 'coverage_keys',
  IS_ALL_COVERAGES: 'is_all_coverages',
  COUNTRIES: 'countries',
  IS_ALL_COUNTRIES: 'is_all_countries',
  STATES: 'states',
  IS_ALL_STATES: 'is_all_states',
  IS_ENABLED: 'is_enabled',
  TEMPLATE_NAME: 'template_name',
  TEMPLATE_TYPE: 'template_type',
  IS_CLAIM_LEVEL: 'is_claim_level',
  BODY_TEMPLATE: 'body_template',
  TITLE_TEMPLATE: 'title_template',
} as const;

export const htmlToTextTemplateParser: (html: string, shouldAddNewLines?: boolean) => string = (
  html: string,
  shouldAddNewLines = false
) => {
  let modifiedHtml = html;

  if (shouldAddNewLines) {
    modifiedHtml = html.replace(/<\/p>|<\/div>/g, '$&\n');
  }

  const parser = new DOMParser();
  const doc = parser.parseFromString(modifiedHtml, 'text/html');

  const links = doc.querySelectorAll('a');
  links.forEach((link) => {
    const href = link.getAttribute('href');
    if (href) {
      link.textContent = href;
    }
  });

  return doc.body.textContent?.trim() || '';
};

export const FROM_TEMPLATE_FORM_KEYS = {
  TEMPLATE_ID: 'template_id',
  TEMPLATE_CONTEXT: 'template_context',
};
