import React from 'react';

import { JusterImage } from '~/components/AiChat/components/JusterImage';

export interface CliveChipProps {
  content: string | React.ReactNode;
}

export const CliveChip: React.FC<CliveChipProps> = ({ content }) => {
  return (
    <div className="flex w-fit items-center gap-8 text-nowrap rounded-full bg-cliveLightGradiant py-8 pl-8 pr-16 bg-blend-hard-light">
      <JusterImage width={28} height={28} />
      <span className="text-base">{content} </span>
    </div>
  );
};
