import type { ReactElement } from 'react';
import React, { useCallback, useState } from 'react';
import axios from 'axios';
import { debounce, keyBy } from 'lodash';

import CardDialog from '~/components/CardDialog';
import { useClaim } from '~/components/ClaimContainer';
import type { ClaimNotificationModel, NotificationsType } from '~/components/ClaimNotificationsCard/types';
import LoadingSwitch from '~/components/core/Loading/LoadingSwitch';
import SkeletonTable from '~/components/core/Skeletons/SkeletonTable';
import { useCms } from '~/components/hooks/useCms';
import useNotificationsPreferences from '~/components/hooks/useNotificationsPreferences';
import type { NotificationsFiltersValues } from '~/components/Notifications/NotificationsFilters';
import NotificationsFilters from '~/components/Notifications/NotificationsFilters';
import NotificationsTable from '~/components/Notifications/NotificationsTable';
import type {
  NotificationsRes,
  NotificationsTableDisplayOptions,
  NotificationTypeCount,
} from '~/components/Notifications/types';
import ViewNotificationCard from '~/components/Notifications/ViewNotificationCard';
import type { NotificationsPreferences } from '~/components/SystemConfiguration/NotificationsConfiguration/types';
import type CLAIM_NOTIFICATIONS_PRIORITY from '~/server_shared/generated-types/CLAIM_NOTIFICATIONS_PRIORITY';
import type { NOTIFICATION_STATUSES } from '~/Types';
import { isUserImpersonated } from '~/UserUtils';
import { isProductionEnv, reportAxiosError, reportErrorInProductionOrThrow } from '~/Utils';

import Text from '../core/TextComponents/Text';
import type { ExposureModel } from '../types/exposure-types';

const DEFAULT_DEFAULT_SORT_ORDER = 'desc';

interface NotificationsScreenProps {
  getClaimNotifications: (
    shouldReturnDismissed: boolean,
    shouldReturnFutureReminders: boolean,
    pageNumber: number,
    resultsPerPage: number,
    sortByColumn: string,
    typeSubtypeFilters: (NotificationsType | 'All')[],
    withReadStatus: boolean,
    sub_organization_ids: number[],
    categories_ids: string[],
    statuses: (keyof typeof NOTIFICATION_STATUSES)[],
    priorities: (keyof typeof CLAIM_NOTIFICATIONS_PRIORITY)[],
    start_due_date: string | null,
    end_due_dat: string | null,
    adjusters_ids: string[]
  ) => Promise<NotificationsRes>;
  displayOptions: NotificationsTableDisplayOptions;
  disableSort?: boolean;
  actionComponent: ReactElement;
  onlyDismissed?: boolean;
  dismissedNavigationComponent?: ReactElement;
  useDefaultAssigneeFromPreferences?: boolean;
}

const NotificationsScreen: React.FC<NotificationsScreenProps> = ({
  getClaimNotifications,
  displayOptions,
  disableSort,
  actionComponent,
  useDefaultAssigneeFromPreferences,
  onlyDismissed,
  dismissedNavigationComponent,
}) => {
  const { configuration, isLoading, isError } = useNotificationsPreferences();
  return (
    <LoadingSwitch isLoading={isLoading} isError={isError}>
      {configuration && (
        <NotificationsScreenInner
          getClaimNotifications={getClaimNotifications}
          displayOptions={displayOptions}
          disableSort={disableSort}
          configuration={configuration}
          actionComponent={actionComponent}
          useDefaultAssigneeFromPreferences={useDefaultAssigneeFromPreferences}
          onlyDismissed={onlyDismissed}
          dismissedNavigationComponent={dismissedNavigationComponent}
        />
      )}
    </LoadingSwitch>
  );
};

interface NotificationScreenInnerProps extends NotificationsScreenProps {
  configuration: NotificationsPreferences;
}

const NotificationsScreenInner: React.FC<NotificationScreenInnerProps> = ({
  getClaimNotifications,
  displayOptions,
  disableSort,
  configuration,
  actionComponent,
  useDefaultAssigneeFromPreferences,
  onlyDismissed,
  dismissedNavigationComponent,
}) => {
  const { user } = useCms();
  const [notifications, setNotifications] = useState<ClaimNotificationModel[]>([]);
  const [totalNotifications, setTotalNotifications] = useState<number>(0);
  const [totalFilteredNotifications, setTotalFilteredNotifications] = useState<number>(0);
  const [exposuresDict, setExposuresDict] = useState<{ [id: string]: ExposureModel }>({});
  const [typesCounts, setTypesCounts] = useState<NotificationTypeCount[]>([]);
  const [notificationsReadStatusDict, setNotificationsReadStatusDict] = useState<{ [id: string]: boolean }>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  // TODO: change to due_date after NGTPA-17913 - this makes the sorting by due_date or datetime
  const [sortByColumn, setSortByColumn] = React.useState({
    order:
      configuration.is_default_sort_order_enabled && configuration.default_sort_order
        ? configuration.default_sort_order
        : DEFAULT_DEFAULT_SORT_ORDER,
    id: 'notification_date',
  });
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    configuration.is_default_notifications_per_page_enabled && configuration.default_notifications_per_page
      ? configuration.default_notifications_per_page
      : 10
  );
  const [notificationToShowId, setNotificationToShowId] = useState<number | undefined>();
  const rowsPerPageOptions = [configuration.default_notifications_per_page || 10];
  const [filters, setFilters] = useState<NotificationsFiltersValues>({
    sub_organization_ids: [],
    categories_ids: [],
    statuses: [],
    types: [],
    priorities: [],
    start_due_date: null,
    end_due_date: null,
    adjusters_ids: [],
  });

  const { claim: claimInContext, onAsyncClaimUpdate } = useClaim(); // might not be in claim context

  const callGetClaimNotifications = React.useCallback(async () => {
    try {
      const res = await getClaimNotifications(
        !!onlyDismissed,
        true,
        page + 1,
        rowsPerPage,
        sortByColumn && `${sortByColumn.order === 'asc' ? '+' : '-'}${sortByColumn.id}`,
        filters.types,
        true,
        filters.sub_organization_ids,
        filters.categories_ids,
        filters.statuses,
        filters.priorities,
        filters.start_due_date,
        filters.end_due_date,
        filters.adjusters_ids
      );

      setNotifications(res.notifications);
      setTotalFilteredNotifications(res.count);
      setTotalNotifications(res.non_filtered_count);
      setTypesCounts(res.notification_type_counts);
      setExposuresDict(keyBy(res.exposures, 'id'));
      setNotificationsReadStatusDict(res.notifications_read_status);
      setIsLoading(false);
    } catch (error) {
      await reportAxiosError(error);
      setIsError(true);
    }
  }, [getClaimNotifications, onlyDismissed, rowsPerPage, sortByColumn, filters, page]);

  const callGetClaimNotificationsDebounce = React.useMemo(
    () => debounce(callGetClaimNotifications, 3000),
    [callGetClaimNotifications]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearch = useCallback(
    debounce((values) => {
      setFilters(values);
      setPage(0);
    }, 300),
    [setFilters]
  );

  React.useEffect((): void => {
    setPage(0);
  }, [rowsPerPage]);

  React.useEffect((): void => {
    callGetClaimNotifications();
  }, [callGetClaimNotifications]);

  const handleSort = (e: React.MouseEvent<HTMLSpanElement>, { id, order }: { id: string; order: 'desc' | 'asc' }) => {
    setSortByColumn({ id, order });
    setPage(0);
  };
  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(Number(e.target.value));
    setPage(0);
  };
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const updateClaimIfInContext = () => {
    if (claimInContext) {
      return onAsyncClaimUpdate();
    } else {
      return Promise.resolve();
    }
  };
  const handleUpdate = async () => {
    await updateClaimIfInContext();
    await callGetClaimNotifications();
  };

  const notificationToShow = notifications.find((c) => c.id === notificationToShowId);

  const handleShowNotification = async (notificationId: number) => {
    setNotificationToShowId(notificationId);

    try {
      if (notificationsReadStatusDict?.[notificationId]) return;
      if (isProductionEnv() && isUserImpersonated(user)) return;

      await axios.post(`/api/v1/claims/notifications/${notificationId}/mark_as_read`);
      await callGetClaimNotifications();
    } catch (error) {
      reportErrorInProductionOrThrow(error);
    }
  };

  const displayLoading = isLoading || isError;

  const getCounterText = () => {
    const getLoadingOrNumber = (val: number) => (isLoading ? '-' : val);
    return `Showing ${getLoadingOrNumber(totalFilteredNotifications)}/${getLoadingOrNumber(totalNotifications)}`;
  };

  return (
    <CardDialog title={`${onlyDismissed ? 'Dismissed' : ''} Notifications`} action={actionComponent}>
      {dismissedNavigationComponent ? dismissedNavigationComponent : null}
      <NotificationsFilters
        onSearch={onSearch}
        isUpdating={isLoading}
        typesCounts={typesCounts}
        defaultAssignee={
          useDefaultAssigneeFromPreferences && configuration.is_default_view_all_in_claims_page ? ['All'] : []
        }
      />
      <Text variant={Text.VARIANTS.LG} weight={Text.WEIGHTS.MEDIUM} className="m-y-24">
        {getCounterText()}
      </Text>
      {!displayLoading ? (
        <NotificationsTable
          notifications={notifications}
          displayOptions={displayOptions}
          exposuresDict={exposuresDict}
          notificationsReadStatusDict={notificationsReadStatusDict}
          pagination={{
            rowsPerPage,
            page,
            rowsPerPageOptions,
            count: totalFilteredNotifications,
            onChangePage: handleChangePage,
            onChangeRowsPerPage: handleChangeRowsPerPage,
          }}
          onUpdateNotification={callGetClaimNotificationsDebounce}
          onHandleShowNotification={handleShowNotification}
          onSortByColumn={handleSort}
          disableSort={disableSort}
          defaultSortOrder={sortByColumn.order}
          onlyDismissed={onlyDismissed}
        />
      ) : null}
      {displayLoading ? <SkeletonTable rowsCount={4} columnsCount={10} isError={isError} /> : null}
      {notificationToShowId && notificationToShow ? (
        <ViewNotificationCard
          onClose={() => setNotificationToShowId(undefined)}
          notification={notificationToShow}
          onUpdate={handleUpdate}
          exposuresDict={exposuresDict}
        />
      ) : null}
    </CardDialog>
  );
};

export default NotificationsScreen;
