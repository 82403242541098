import React from 'react';

import Text from '~/components/core/TextComponents/Text';
import { MailIcon } from '~/components/icons';

interface MarkAsUnreadButtonProps {
  onClick: () => Promise<void>;
  disabled?: boolean;
}

const MarkAsUnreadButton: React.FC<MarkAsUnreadButtonProps> = ({ onClick, disabled }) => {
  return (
    <button
      className="group flex cursor-pointer items-center border-none bg-transparent outline-none"
      onClick={onClick}
      disabled={disabled}
    >
      <MailIcon size={20} className="cursor-pointer group-hover:fill-teal-700" />
      <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.SEMI_BOLD} className="px-4 group-hover:text-teal-700">
        MARK AS UNREAD
      </Text>
    </button>
  );
};

export default MarkAsUnreadButton;
